import axios from 'axios';
import AuthHeader from './AuthHeader';
import AuthUser from './AuthUser';

//DEVELOP
//const API_URL_TENT = 'https://dev-estadio.apilmx.com/' 
//const API_URL_TENT_SERVERLESS = 'https://zcyp4tstle.execute-api.us-east-1.amazonaws.com/api/'
//rekognition
//const API_URL_RKGN_SEARCH = 'https://3kmlopcyz0.execute-api.us-east-1.amazonaws.com/api/search' // 'https://qpivierg9l.execute-api.us-east-1.amazonaws.com/api/search'
//const API_URL_RKGN_COMPARE = 'https://owtpwcmaq8.execute-api.us-east-1.amazonaws.com/api/compare'

// PRODUCTION
const API_URL_TENT = 'https://estadio.apilmx.com/'
const API_URL_TENT_SERVERLESS = 'https://aodthufffj.execute-api.us-east-1.amazonaws.com/api/'
//rekognition
const API_URL_RKGN_SEARCH = 'https://76a8ipwspc.execute-api.us-east-1.amazonaws.com/api/search'  //'https://dxkqm9hyc3.execute-api.us-east-1.amazonaws.com/api/search'
const API_URL_RKGN_COMPARE = 'https://lp7de56iih.execute-api.us-east-1.amazonaws.com/api/compare'
class DataService {
    login(datos){
        return axios.post(API_URL_TENT + 'login', datos, { headers: {'Content-Type':'application/json'} });
    }
    getCheck(ticket) {
        return axios.get(API_URL_TENT + `check/${ticket}?user=${AuthUser()}`, { headers: AuthHeader() });
    }
    getDelete(ticket) {
        return axios.get(API_URL_TENT + `delete/${ticket}?user=${AuthUser()}`, { headers: AuthHeader() });
    }
    register(datos){
        return axios.post(API_URL_TENT + 'reg'+`?user=${AuthUser()}`, datos, { headers: {...AuthHeader(), 'Content-Type': `multipart/form-data`} });
    }
    updateRegister(datos){
        return axios.put(API_URL_TENT + 'reg'+`?user=${AuthUser()}`, datos, { headers: {...AuthHeader(), 'Content-Type': `multipart/form-data`} });
    }

    getScan(ticket) {
        return axios.get(API_URL_TENT + `scan/${ticket}?user=${AuthUser()}`, { headers: AuthHeader() });
    }

    setValid(ticket) {
        return axios.get(API_URL_TENT + `valid/${ticket}?user=${AuthUser()}`, { headers: AuthHeader() });
    }

    search(datos){
        return axios.post(API_URL_RKGN_SEARCH, datos, { headers: {'Content-Type': 'application/json'}} );
    }

    compare(datos){
        return axios.post(API_URL_RKGN_COMPARE, datos, { headers: {'Content-Type': 'application/json'}} );
    }

    uploadImg(url, datos){
        return axios.post(url, datos, { headers: {'Content-Type': 'multipart/form-data'}} );
    }

    generarQR(ticket){
        return axios.get(API_URL_TENT + "genqr/" + ticket);
    }

    saveLogs(url, datos){
        return axios.post(url, datos, { headers: {'Content-Type': 'application/json'}})
    }

    /* PARA CONTROL DE ACCESO */
    getCheckCA(ticket) {
        return axios.get(API_URL_TENT_SERVERLESS + `check/${ticket}?user=${AuthUser()}`, { headers: AuthHeader() });
    }
    getScanCA(ticket) {
        return axios.get(API_URL_TENT_SERVERLESS + `scan/${ticket}?user=${AuthUser()}`, { headers: AuthHeader() });
    }
    setValidCA(ticket) {
        return axios.get(API_URL_TENT_SERVERLESS + `valid/${ticket}?user=${AuthUser()}`, { headers: AuthHeader() });
    }

}

export default new DataService();