<template>
  
    <div v-if="user" style="margin-bottom: 20px;">
      <button class="btn btn-danger mollen-font" @click="salir">Cerrar sesión</button>
    </div>
  
</template>

<script>

import authService from  "/src/services/AuthService"
export default {
    name:"closeSession",
    components: {
    },
    data() {
      return {
        user: null
      }
    },
    methods:{
      salir(){
        authService.logout()
          this.$router.go()
      },
    },
    mounted(){
      this.user = localStorage.getItem('user')
    }
}
</script>

<style>

</style>