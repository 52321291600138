<template>
    <div class="cont">
        <h4>{{title}}</h4>
        <div class="row" v-if="1==2">
            <div style="text-align:center">
                <br>
                <img src="../assets/danger.gif" alt="danger" style="width:64px; margin-bottom: 20px">
            </div>
        </div>
        <h5 style="display:none">
            {{data}}
        </h5>
        <div style="text-align: left; margin: 30px">
            <div style="display:none">
                <h5>Confirmar los siguientes datos:</h5>
                <div v-for="(m, i) in msgs" :key="i">
                    <p v-if="m=='Fotografía'">* {{m}} 
                        <a :href="rutaImgExpulsado" target="_blank" @click="()=>verExpulsados">Ver</a>
                        <!--<button type="button" @click="verExpulsados">Ver</button>-->
                    </p>
                    <p v-else>* {{m}}</p>
                </div>
            </div>
            <div>
                <h5>De acuerdo a la información ingresada, nuestros registros indican que en un partido previo incumpliste con el “Código de Comportamiento en el Estadio”. Por lo anterior, tu registro no puede ser completado y tu acceso al estadio no será permitido.</h5>
            </div>
        </div>
        <br>
        <!--<button type="button" @click="continuar(), popModal()" class="btn btn-success mb-3 mollen-font">Continuar con el registro</button>&nbsp;-->
        <button type="button" @click="cancelar(), popModal()" class="btn btn-success mb-3 mollen-font">Aceptar</button>
    </div>
</template>
<script>
    import {popModal} from "jenesius-vue-modal";
    import dataService from '../services/DataService'
    //import ModalExpelled from "./ModalExpelled.vue";
    export default {
        name: 'ModalShowExp',
        props: {
            continuar: Function,
            cancelar: Function,
            title: String,
            data: String,
            msgs: Array,
            rutaImgExpulsado: {type: String, default: ""},
            //Datos para logs
            name: {type: String, default: ""},
            email: {type: String, default: ""},
            similarity: {type: String, default: ""},
            url_match: {type: String, default: ""}  // changos jaja es la misma de rutaImgExpulsado
        },
        data(){
            return{
                verFotografia: false
            }
        },
        setup(){
            return {
				popModal
            }
        },
        methods: {
            verExpulsados(){
                //console.log("show expulsados...")
                //this.verFotografia=true
                //openModal(ModalExpelled,{rutaExpulsado: this.rutaImgExpulsado})
            }
        }, 
        mounted(){
            if(this.name.length>0 && this.email.length>0){
                let data = {
                        "nombre": this.name,
                        "email": this.email,
                        "similarity": this.similarity,
                        "url_match": this.url_match
                    }
                dataService.saveLogs('https://u69gox9pa2.execute-api.us-east-1.amazonaws.com/api/logs', data)
                .then(respL=>{
                    console.log(respL)
                })
            }
        }
    }
</script>
<style>
    .cont{
        background-color: white;
        border: ridge 1px black;
        padding: 2%;
    }
</style>