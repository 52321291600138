<template>
    <mainHeader></mainHeader>
    <div
        class="container"
        style="text-align: center; margin-top: 25px"
        v-if="ticketValid"
    >
        <loading
            v-model:active="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            :width="150"
            color="#02b348"
            loader="dots"
        />
        <form v-on:submit.prevent="submitForm">
            <div class="row form-Margin">
                <div class="mb-3">
                    <label class="form-label">
                        <b>Boleto: {{ originalTicket || "Nothing" }}</b>
                    </label>
                    <br />
                    <label for="" class="haeock-font">{{ eventMatch }}</label>
                </div>
                <div class="row mb-3" v-if="menorEdad">
                    <div class="col-sm-12 col-md-8 col-lg-6">
                        <div class="col-sm-12">
                            <label for="inputNombre" class="form-label mollen-font">
                                <span style="color:#00852a;font-weight: 600;font-size: 18px;"> * </span>
                                Nombre de la madre, padre o tutor:
                            </label>
                        </div>
                        <div class="col-sm-12">
                            <input
                                type="text"
                                class="form-control"
                                id="inputNombre"
                                name="name"
                                placeholder=""
                                v-on:blur="sanitizeT"
                                @input="sanitizeT"
                                v-model="form.nameTutor"
                            />
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-6">
                        <div class="col-sm-12 mollen-font">
                            <span style="color:#00852a;font-weight: 600;font-size: 18px;"> * </span> Mes y Año de Nacimiento
                        </div>
                        <div class="col-sm-12">
                            <div class="center">
                                <select
                                    class="form-control c-in-date-m"
                                    name="month"
                                    id="month"
                                    v-model="selectedMonth"
                                >
                                    <option :value="-1">Mes</option>
                                    <option
                                        v-for="(mes, i) in meses"
                                        :key="i"
                                        :value="i"
                                    >
                                        {{ mes }}
                                    </option>
                                </select>
                                <span
                                    style="
                                        width: 5%;
                                        text-align: center;
                                        display: inline-block;
                                    "
                                >
                                    /
                                </span>
                                <select
                                    class="form-control c-in-date-y"
                                    name="year"
                                    id="year"
                                    v-model="selectedYear"
                                >
                                    <option value="0">Año</option>
                                    <option
                                        v-for="(year, i) in years"
                                        :key="i"
                                        :value="year"
                                    >
                                        {{ year }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div
                        :class="
                            menorEdad
                                ? 'col-sm-12 col-md-12 col-lg-12'
                                : 'col-sm-12 col-md-8 col-lg-6'
                        "
                    >
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="col-sm-12">
                                    <label for="inputNombre" class="form-label mollen-font">
                                        <span style="color:#00852a;font-weight: 600;font-size: 18px;"> * </span>
                                        {{menorEdad ? "Nombre del menor" : "Nombre" }}</label
                                    >
                                </div>
                                <div class="col-sm-12">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="inputNombre"
                                        name="name"
                                        v-on:blur="sanitizeName"
                                        @input="sanitizeName"
                                        placeholder=""
                                        v-model="form.name"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="col-sm-12">
                                    <label for="inputNombre" class="form-label mollen-font">
                                        <span style="color:#00852a;font-weight: 600;font-size: 18px;"> * </span>
                                        {{
                                            menorEdad
                                                ? "Apellido paterno del menor"
                                                : "Apellido paterno"
                                        }}</label
                                    >
                                </div>
                                <div class="col-sm-12">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="inputApellido"
                                        name="lastname"
                                        placeholder=""
                                        v-on:blur="sanitizeLastName"
                                        @input="sanitizeLastName"
                                        v-model="form.last_name"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="col-sm-12">
                                    <label
                                        for="inputNombre"
                                        class="form-label mollen-font"
                                        >{{
                                            menorEdad
                                                ? "Apellido materno del menor"
                                                : "Apellido materno"
                                        }}</label
                                    >
                                </div>
                                <div class="col-sm-12">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="inputApellidoM"
                                        name="m_last_name"
                                        placeholder=""
                                        v-on:blur="sanitizeMLastName"
                                        @input="sanitizeMLastName"
                                        v-model="form.m_last_name"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-6" v-if="!menorEdad">
                        <div class="col-sm-12 mollen-font">
                            <span style="color:#00852a;font-weight: 600;font-size: 18px;"> * </span> Mes y Año de Nacimiento
                        </div>
                        <div class="col-sm-12">
                            <div class="center">
                                <select
                                    class="form-control c-in-date-m"
                                    name="month"
                                    id="month"
                                    v-model="selectedMonth"
                                >
                                    <option :value="-1">Mes</option>
                                    <option
                                        v-for="(mes, i) in meses"
                                        :key="i"
                                        :value="i"
                                    >
                                        {{ mes }}
                                    </option>
                                </select>
                                <span
                                    style="
                                        width: 5%;
                                        text-align: center;
                                        display: inline-block;
                                    "
                                >
                                    /
                                </span>
                                <select
                                    class="form-control c-in-date-y"
                                    name="year"
                                    id="year"
                                    v-model="selectedYear"
                                >
                                    <option value="0">Año</option>
                                    <option
                                        v-for="(year, i) in years"
                                        :key="i"
                                        :value="year"
                                    >
                                        {{ year }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="inputEmail" class="form-label"
                        >Correo: {{ form.email }}</label
                    >
                </div>
                <div class="mb-3">
                    <label for="inputMovil" class="form-label mollen-font"
                        >Número de celular:</label
                    >
                    <input
                        type="tel"
                        oninput="this.value=this.value.replace(/[^0-9]+/, '');"
                        class="form-control"
                        id="inputMovil"
                        maxlength="10"
                        name="mobile"
                        placeholder=""
                        v-model="form.mobile"
                    />
                </div>

                <!--Campo de la identificacion-->
                <div class="mb-3">
                    <label for="inputINE" class="form-label fontallbold">
                        <span style="color:#00852a;font-weight: 600;font-size: 18px;"> * </span> Identificación oficial con fotografia:
                    </label>
                    <div class="row">
                        <img :src="imgIdentificacionOriginal" alt="Identificación" style="width: 25%;margin-left: auto;margin-right: auto;display: block; cursor:pointer"
                            @click="visualizar">
                    </div>
                    <br />
                    <div class="row">
                        <div :class="isMobil?'col-lg-12':'col-lg-11'" v-show="isMobil">
                            <label for="inputINE" class="subir">
                                <i class="fas fa-cloud-upload-alt"></i> 
                                <img src="../assets/icono-camara-gris.png" alt="cámara" style="cursor: pointer; width: 135px"/>
                            </label>
                            <input type="file" accept="image/*" @change="changeImg($event ,'ine')" class="form-control" id="inputINE" name="ine" placeholder="" v-show="false">
                            <img :src="dataUrlIneBase64" alt="selfie" v-if="dataUrlIneBase64.length>0" style="width:100%"/>
                        </div>
                        <div class="col-lg-12" v-if="!isMobil">
                        <img src="../assets/icono-camara-gris.png" alt="cámara" @click="startCameraStream($event, 'ine')" style="cursor: pointer; width: 135px"
                            v-show="!isCameraOpenIne" v-if="dataUrlIneBase64.length==0" />
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-if="!isMobil">
                    <img :src="dataUrlIneBase64" alt="selfie" v-if="dataUrlIneBase64.length>0"/>
                    <div v-show="isCameraOpenIne">
                        <br/>
                        <div style="margin-top: -25px;">
                            <button class="btn btn-danger mb-3 mollen-font" @click="cancelarCaptura($event, 'ine')">Cancelar</button>&nbsp;
                            <button class="btn btn-success mb-3 mollen-font" @click="capture($event, 'ine')" style="background-color: #00852a;">Capturar</button>
                        </div>
                        <div class="camera-box" style="margin-top: 50px">
                            <div class="camera-canvas">
                                <div style="postion: absolute; width: 100%; height: 410px; background-size: 450px 300px; opacity: 0.9; z-index: 300000; background-repeat:no-repeat; background-position: center center; background-image: url('/img/siluetaIne.png');"></div>
                                <video ref="cameraIne" :width="canvasWidth" autoplay style="margin-top:-480px"></video>
                                <canvas v-show="false" id="ineTaken" ref="canvasIne" :width="canvasWidth" :height="canvasHeight"></canvas>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isCameraOpenIne && dataUrlIneBase64.length>0">
                        <br/>
                        <button class="btn btn-info mb-3" @click="changeSelfie($event, 'ine')">Cambiar identificación oficial</button>
                    </div>
                </div>
                <!--FIN Campo de la identificacion-->
                
                <!--Campo de la SELFIE-->
                <div class="mb-3">
                    <label for="inputFoto" class="form-label fontallbold">
                      <span style="color:#00852a;font-weight: 600;font-size: 18px;"> * </span>Fotografía / Selfie:
                    </label>
                    <div class="row">
                        <img :src="imgSelfieOriginal" alt="Selfie" style="width: 25%;margin-left: auto;margin-right: auto;display: block; cursor:pointer"
                            @click="visualizar">
                    </div>
                    <br />
                    <div class="row">
                        <div :class="isMobil?'col-lg-12':'col-lg-11'" v-show="isMobil">
                            <label for="inputFoto" class="subir">
                                <i class="fas fa-cloud-upload-alt"></i> 
                                <img src="../assets/icono-camara-gris.png" alt="cámara" style="cursor: pointer; width: 135px"/>
                            </label>
                            <input type="file" accept="image/*" @change="changeImg($event ,'selfie')" class="form-control" id="inputFoto" name="photo" placeholder="" v-show="false">
                            <img :src="dataUrlSelfieBase64" alt="selfie" v-if="dataUrlSelfieBase64.length>0" style="width:100%"/>
                        </div>
                        <div class="col-lg-12"  v-if="!isMobil">
                        <img src="../assets/icono-camara-gris.png" alt="cámara" @click="startCameraStream($event)" style="cursor: pointer; width: 135px"
                            v-show="!isCameraOpen" v-if="dataUrlSelfieBase64.length==0" />
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-if="!isMobil">
                    <img :src="dataUrlSelfieBase64" alt="selfie" v-if="dataUrlSelfieBase64.length>0" />
                    
                    <div v-show="isCameraOpen">
                    <br/>
                    <div>
                        <button class="btn btn-danger mb-3" @click="cancelarCaptura($event)">Cancelar</button>&nbsp;
                        <button class="btn btn-success mb-3" @click="capture($event)">Capturar selfie</button>
                    </div>
                    <div class="camera-box" style="margin-top: 65px">
                        <div class="camera-canvas">
                            <div style="postion: absolute; width: 100%; height: 380px; background-size: 500px 380px; opacity: 0.5; z-index: 300000; background-repeat:no-repeat; background-position: center center; background-image: url('/img/silueta.png');"></div>
                            <video ref="camera" :width="canvasWidth" :height="canvasHeight" autoplay style="margin-top: -480px"></video>
                            <canvas v-show="false" id="photoTaken" ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
                        </div>
                    </div>
                    </div>
                    <div v-if="!isCameraOpen && dataUrlSelfieBase64.length>0">
                    <br/>
                    <button class="btn btn-info mb-3" @click="changeSelfie($event)">Cambiar selfie</button>
                    </div>
                </div>
                <!--FIN Campo de la SELFIE-->
                <hr>
                <div style="text-align:left" v-if="menorEdad">
                    <b>NOTA:</b> Las fotografías de tu identificación oficial y la selfie del menor deberán ser capturadas con tu dispositivo. 
                </div>
                <div style="text-align:left" v-else>
                    <b>NOTA:</b> Las fotografías de tu identificación oficial y selfie deberán ser capturadas con tu dispositivo.
                </div>
                <div class="mb-3" style="text-align:left">
                  <b>Los campos marcados con <span style="color:#00852a;font-weight: 600;font-size: 18px;"> * </span> son obligatorios.</b>
                </div>
                <div class="mb-3">
                    <button
                        type="submit"
                        class="btn btn-success mb-3 mollen-font"
                    >
                        Actualizar</button
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        class="btn btn-danger mb-3 mollen-font"
                        @click="cancelEdit"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div
        class="container"
        style="text-align: center; margin-top: 25px"
        v-if="!ticketValid"
    >
        <h1>{{ notFound }}</h1>
    </div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import Compressor from "compressorjs";
import mainHeader from '../components/header'
import authService from  "../services/AuthService"
import dataService from '../services/DataService'
import detectaUsuarios from '../services/DetectaUsuarios'
//import Modal from "./Modal.vue";
import ModalWarning from "./ModalWarning.vue";
import ModalPrintQR from "./ModalPrintQR.vue";
import ModalShowExp from "./ModalShowExp.vue"
import ModalPreviewImg from "./ModalPreviewImg.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    name: "EditData",
    components: {
        Loading,mainHeader
    },
    props: {
        ticket: String,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            isMobil: false,
            menorEdad: false,
            isCameraOpen: false,
            isCameraOpenIne: false,
            canvasHeight:480,
            canvasWidth: 600,
            notFound: "",
            items: [],
            dataUrlIneBase64: "",
            dataUrlSelfieBase64: "",
            ticketValid: false,
            originalTicket: "",
            originalAge: "",
            originalEmail: "",
            eventMatch: "",
            selectedMonth: -1,
            selectedYear: "0",
            meses: [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ],
            years: [],
            form: {
                barCodeId: "",
                name: "",
                last_name: "",
                m_last_name: "",
                nameTutor: "",
                email: "",
                mobile: "",
                isChild: false,
                ine: null,
                photo: null,
            },
            imgIdentificacionOriginal: "",
            imgSelfieOriginal: "",
            peticionImg1: "",
            peticionImg2: "",
            msgsExpl: []
        };
    },
    methods: {
        visualizar(){
            this.form.barCodeId = this.originalTicket
            openModal(
                ModalPreviewImg,{
                datosBoleto: this.form,
                aceptar:  ()=>{
                    // cancelar
                }
            })
        },
        salir(){
            authService.logout()
            this.$router.push("/")
        },
        cancelEdit() {
            let ticket = this.originalTicket
            this.$router.push({name: "checkTicket", params: { numeroBoletoProps: ticket } })
        },
        sanitizeT() {
            this.form.nameTutor = this.form.nameTutor.replace(
                /[^a-zA-ZáéíóúÁÉÍÓÚüñÑ\s]+/,
                ""
            );
            //console.log(this.form.nameTutor);
        },
        sanitizeName() {
            this.form.name = this.form.name.replace(
                /[^a-zA-ZáéíóúÁÉÍÓÚüñÑ\s]+/,
                ""
            );
            //console.log(this.form.nameTutor);
        },
        sanitizeLastName() {
            this.form.last_name = this.form.last_name.replace(
                /[^a-zA-ZáéíóúÁÉÍÓÚüñÑ\s]+/,
                ""
            );
            //console.log(this.form.last_name);
        },
        sanitizeMLastName() {
            this.form.m_last_name = this.form.m_last_name.replace(
                /[^a-zA-ZáéíóúÁÉÍÓÚüñÑ\s]+/,
                ""
            );
            //console.log(this.form.m_last_name);
        },
        onCancel() {
            console.log("User cancelled the loader.");
        },

        startCameraStream(ev, ineSelfie) {
            ev.preventDefault();
            this.form.photo = "";

            if (ineSelfie == "ine" && document.getElementById("inputIne")) {
                document.getElementById("inputIne").value = "";
            }
            if (ineSelfie == "selfie" && document.getElementById("inputFoto")) {
                document.getElementById("inputFoto").value = "";
            }
            const constraints = (window.constraints = {
                audio: false,
                video: true,
            });
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    if (ineSelfie == "ine") {
                        this.$refs.cameraIne.srcObject = stream;
                    } else {
                        this.$refs.camera.srcObject = stream;
                    }
                })
                .catch((error) => {
                    alert(
                        "Browser doesn't support or there is some errors." +
                            error
                    );
                });
            if (ineSelfie == "ine") {
                this.isCameraOpenIne = true;
                this.isCameraOpen = false;
            } else {
                this.isCameraOpenIne = false;
                this.isCameraOpen = true;
            }
        },

        stopCameraStream(ineSelfie) {
            let tracks = null;
            if (ineSelfie == "ine") {
                tracks = this.$refs.cameraIne.srcObject
                    ? this.$refs.cameraIne.srcObject.getTracks()
                    : null;
            } else {
                tracks = this.$refs.camera.srcObject
                    ? this.$refs.camera.srcObject.getTracks()
                    : null;
            }
            if (tracks != null) {
                tracks.forEach((track) => {
                    track.stop();
                    //console.log(track);
                });
            } else {
                if (ineSelfie == "ine") {
                    this.dataUrlIneBase64 = "";
                } else {
                    this.dataUrlSelfieBase64 = "";
                }
            }
            if (ineSelfie == "ine") {
                this.isCameraOpenIne = false;
            } else {
                this.isCameraOpen = false;
            }
        },
        changeSelfie(ev, ineSelfie) {
            ev.preventDefault();
            this.startCameraStream(ev, ineSelfie);
        },
        cancelarCaptura(ev, ineSelfie) {
            ev.preventDefault();
            if (ineSelfie == "ine") {
                this.dataUrlIneBase64 = "";
            } else {
                this.dataUrlSelfieBase64 = "";
            }
            this.stopCameraStream(ineSelfie);
        },
        capture(ev, ineSelfie) {
            ev.preventDefault();
            const FLASH_TIMEOUT = 50;
            let self = this;
            setTimeout(() => {
                const context =
                    ineSelfie == "ine"
                        ? self.$refs.canvasIne.getContext("2d")
                        : self.$refs.canvas.getContext("2d");
                context.drawImage(
                    ineSelfie == "ine"
                        ? self.$refs.cameraIne
                        : self.$refs.camera,
                    0,
                    0,
                    self.canvasWidth,
                    self.canvasHeight
                );
                if (ineSelfie == "ine") {
                    self.dataUrlIneBase64 =
                        self.$refs.canvasIne.toDataURL("image/jpeg");
                    console.log(self.dataUrlIneBase64);
                } else {
                    self.dataUrlSelfieBase64 =
                        self.$refs.canvas.toDataURL("image/jpeg");
                    console.log(self.dataUrlSelfieBase64);
                }
                //.replace("image/jpeg", "image/octet-stream");

                //self.addToPhotoGallery(dataUrl);
                //self.uploadPhoto(dataUrl);
                self.stopCameraStream(ineSelfie);
            }, FLASH_TIMEOUT);
        },

        dataURLtoFile(dataURL, filename) {
            let arr = dataURL.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        validateTicket(ticket) {
            this.isLoading = true;
            this.ticketValid = true;
            this.getData(ticket)
        },
        changeImg(ev, ineSelfie){
            //console.log(ineSelfie)
            const file = ev.target.files[0];
            let url = URL.createObjectURL(file);
            if(ineSelfie=='ine'){
                this.dataUrlIneBase64 = url
            } else {
                this.dataUrlSelfieBase64 = url
            }
            //console.log(url)
        },
        async getData(ticket) {
            this.isLoading = true;
            var data = null;
            dataService.getScan(ticket)
            .then((response) => {
                this.isLoading = false;
                if (response.status == 200) {
                    data = response.data;
                    if (data !== null && data !== undefined) {
                        this.form.nameTutor = data.tutor;
                        this.originalTicket = data.barCodeId;
                        this.originalEmail = data.email;
                        this.originalAge = data.isChild;
                        this.form.name = data.name;
                        this.form.last_name = data.last_name;
                        this.form.m_last_name = data.m_last_name;
                        this.form.email = data.email;
                        this.form.mobile = data.mobile;
                        this.form.ine = data.ine
                        this.form.photo = data.photo
                        this.imgIdentificacionOriginal = data.ine
                        this.imgSelfieOriginal = data.photo
                        this.eventMatch = data.match;
                        this.menorEdad = data.isChild ? true : false;
                        this.selectedMonth = this.meses.findIndex(
                            (value) => value == data.date.split("/")[0]
                        );
                        this.selectedYear = data.date.split("/")[1];
                        this.peticionImg1 = data.id
                        this.peticionImg2 = data.selfie
                    } else {
                        this.ticketValid = false;
                        this.notFound = "404 Not Found";
                    }
                }
            })
            .catch((e) => {
                this.isLoading = false;
                if(e.response.status==401){
                    this.salir()
                }
            });
        },
        async submitForm(e) {
            e.preventDefault();
            this.rutaImgExpulsado=""
            this.msgsExpl=[]
            this.form.ine = document.getElementById("inputINE") && document.getElementById("inputINE").value.length > 0 ? 
                document.getElementById("inputINE").value : this.dataUrlIneBase64;
            this.form.photo = document.getElementById("inputFoto") && document.getElementById("inputFoto").value.length > 0 ? 
                document.getElementById("inputFoto").value : this.dataUrlSelfieBase64;

            if (this.validateForm()) {

                let userT = this.form.name + ' ' + this.form.last_name
                let emailT = this.originalEmail
                let resp = detectaUsuarios.getUserDetected(userT, emailT)
                if(resp.length>0 && resp[0].value){
                    // guardar log para detectado expulsado por correo
                    this.showModalExp(userT, emailT, "", "")
                    return
                    /*
                    resp.forEach(respMsg=>{
                        this.msgsExpl.push(respMsg.msg)
                    })
                    */
                }
                this.form.ine = document.getElementById("inputINE") && document.getElementById("inputINE").value.length > 0 ? 
                    document.getElementById("inputINE").value : this.dataUrlIneBase64;
                this.form.photo = document.getElementById("inputFoto") && document.getElementById("inputFoto").value.length > 0 ? 
                    document.getElementById("inputFoto").value : this.dataUrlSelfieBase64;
                this.isLoading = true;

                // PETICION 2 para la SELFIE
                //console.log(this.peticionImg2)
                let urlUpld2 = (this.peticionImg2.url)
                let fields2 = (this.peticionImg2.fields)
                var bodyFormData2 = new FormData()
                //console.log(urlUpld2)
                //console.log(fields2)
                var nameSelfie = ""
                Object.entries(fields2).forEach(([key, value]) => {
                    //console.log(key + ' - ' + value)
                    if(key=='key'){
                        nameSelfie = value.replace(".jpg", "")
                    }
                    bodyFormData2.append(key, value);
                });
                if( document.getElementById('inputFoto') && document.getElementById('inputFoto').files.length>0 ){
                    bodyFormData2.append('file', await this.compress(document.getElementById('inputFoto').files[0]), document.getElementById('inputFoto').files[0].name);
                } else {
                    // Aqui significa que esta en la PC y tomaron selfie con la webcam
                    let capturedPhotoFile = this.dataURLtoFile(this.dataUrlSelfieBase64, 'selfie.jpg')
                    //console.log(capturedPhotoFile)
                    //bodyFormData2.append('file', await this.compress(capturedPhotoFile), capturedPhotoFile.name);
                    bodyFormData2.append('file', capturedPhotoFile)
                }
                dataService.uploadImg(urlUpld2, bodyFormData2)
                .then(async (res) => {
                    if(res.status==204) {
                        //Se subio la selfie correctamente
                        //console.log(this.msgsExpl)
                        //Una vez que la imagen de la selfie se haya subido con exito vamos a ejecutar 
                        // servicio para buscar la selfie y comparar con los expulsados del estadio
                        dataService.search({selfie: nameSelfie})
                        .then(async respSearch=>{
                            //console.log(respSearch.status)
                            if(respSearch.status == 200){
                                //Subir img de indentificacion
                                this.uploadIdnt(nameSelfie)
                            } else {
                                this.isLoading = false
                                this.showMsg("Atención", "El registro no puede ser realizado.")
                            }
                        })
                        .catch(err=>{
                            //console.log(err.response)
                            if(err.response.status==500){
                                this.isLoading = false
                                this.showMsg("Atención", "Lo sentimos. La fotografía/selfie no cumple con los requisitos. Favor de tomarla una vez más e intentarlo de nuevo.")
                            } else {
                                //this.msgsExpl.push("Fotografía")
                                //Subir img de indentificacion
                                this.rutaImgExpulsado = err.response.data.url_match
                                //this.uploadIdnt(nameSelfie)
                                // detextado por la imagen, mandar form para log por imagen
                                this.showModalExp(userT, emailT, err.response.similarity, err.response.data.url_match)
                            }
                        })
                        
                    }
                })
                .catch(err=>console.log(err))
            }
        },
        async uploadIdnt(nameSelfie){
            // Subir imagen de identificacion
            // aqui va peticion para imagen 2, ya que NO es uno de los expulsados... seguimos adelante
            // PETICION 1 para la IDENTIFICACION
            let urlUpld1 = (this.peticionImg1.url)
            let fields1 = (this.peticionImg1.fields)
            var bodyFormData1 = new FormData()
            //console.log(urlUpld1)
            //console.log(fields1)
            var nameIdnt=""
            Object.entries(fields1).forEach(([key, value]) => {
                //console.log(key + ' - ' + value)
                if(key=='key'){
                    nameIdnt = value.replace(".jpg", "")
                }
                bodyFormData1.append(key, value);
            });
            if( document.getElementById('inputINE') && document.getElementById('inputINE').files.length>0 ){
                bodyFormData1.append('file', await this.compress(document.getElementById('inputINE').files[0]), document.getElementById('inputINE').files[0].name);
            } else {
                // Aqui significa que esta en la PC y tomaron foto de la identificacion con la webcam
                let capturedIneFile = this.dataURLtoFile(this.dataUrlIneBase64, 'identificacion.jpg')
                //console.log(capturedIneFile)
                //bodyFormData1.append('photo', await this.compress(capturedIneFile), capturedIneFile.name);
                bodyFormData1.append('file', capturedIneFile)
            }
            dataService.uploadImg(urlUpld1, bodyFormData1).then((res) => {
                if(res.status==204) {
                    // Se subio la imagen de identificacion
                    this.continuaRegistro(nameIdnt, nameSelfie)
                    // El codigo de abajo era para mostrar msjs de porque se habia detectado un expulsado, que ya no va -.-
                    /*
                    //Revisar si hay mensajes de expulsados mostrarlos en un emergente y tener la oción de continuar
                    if(this.msgsExpl.length>0){
                        this.isLoading = false
                        //Hay coincidencias con expulsados
                        console.log(this.rutaImgExpulsado)
                        openModal(ModalShowExp,{
                            title:"Atención",
                            data: "Por favor localice al Coordinador de la Mesa de Ayuda.",
                            msgs: this.msgsExpl,
                            rutaImgExpulsado: this.rutaImgExpulsado, 
                            continuar:  ()=>{
                                console.log("Continuar con el registro.")
                                this.isLoading = true
                                this.continuaRegistro(nameIdnt, nameSelfie)
                            },
                            cancelar: ()=>{
                                console.log("Cancelar registro.")
                            }
                        })
                    } else {
                        //Seguimos normal
                        this.continuaRegistro(nameIdnt, nameSelfie)
                    }
                    */
                }
            })
            .catch(err=>console.log(err))
        },
        continuaRegistro(nameIdnt, nameSelfie){
            /* Aquí vamos a revisar si es menor ir al registro */
            var combo = document.getElementById("year");
            var year = combo.options[combo.selectedIndex].text;
            var bodyFormData = new FormData();
            bodyFormData.append("barCodeId", this.originalTicket);
            bodyFormData.append("hash", this.$CryptoJS.SHA512(this.originalTicket).toString() );
            bodyFormData.append("name", this.form.name);
            bodyFormData.append("last_name", this.form.last_name);
            bodyFormData.append("m_last_name", this.form.m_last_name);
            bodyFormData.append("email", this.originalEmail);
            bodyFormData.append("mobile", this.form.mobile);
            if (this.menorEdad) {
                bodyFormData.append("tutor", this.form.nameTutor);
            }
            bodyFormData.append("isChild", this.originalAge);
            bodyFormData.append("date", this.meses[this.selectedMonth] + "/" + year );
            bodyFormData.append('key_id', nameIdnt);
            bodyFormData.append('key_selfie', nameSelfie);
            /* AQUI VALIDAMOS SI ES MENOR NO SE DEBE USAR REKOGNITION para comparar las caras */
            if(this.menorEdad || (this.form.nameTutor && this.form.nameTutor.length>0)){
                // Por lo tanto vamos a proceder con la actualizacion
                dataService.updateRegister(bodyFormData)
                    .then((res) => {
                        this.isLoading = false;
                        if (res.status == 200) {
                            //console.log(res)
                            openModal(
                            ModalPrintQR,{
                                imagenQr: res.data.ticket, 
                                numeroBoleto: res.data.barCodeId,
                                nombre: res.data.name + " " + res.data.last_name + " " + res.data.m_last_name,
                                cancelar:  ()=>{
                                  // cancelar
                                    this.$router.push("/checkTicket")
                                },
                                completed: ()=>{
                                    openModal(ModalWarning, {
                                        title: "Confirmación",
                                        data: "Los datos se actualizaron correctamente",
                                        actualizados: true,
                                        updated: () => {
                                            this.$router.push("/checkTicket");
                                        },
                                    });
                                }
                            })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if(error.response.status==401){
                            this.salir()
                        }
                        openModal(ModalWarning, {
                            title: "Falló la actualizacion de los datos",
                            data: error,
                        });
                    })
                    .finally(() => {});

            } else {

                //Una vez que se haya subido la identificacion vamos a comparar las imagenes selfie vs identificacion
                dataService.compare({id: nameIdnt, selfie: nameSelfie})
                .then(respCmpr=>{
                    //console.log(respCmpr.status)
                    if(respCmpr.status==200){
                        // la identificacion y la selfie corresponden a la misma persona
                        // Por lo tanto vamos a proceder con la actualizacion
                        dataService.updateRegister(bodyFormData)
                        .then((res) => {
                            this.isLoading = false;
                            if (res.status == 200) {
                                console.log(res)
                                openModal(
                                ModalPrintQR,{
                                    imagenQr: res.data.ticket, 
                                    numeroBoleto: res.data.barCodeId,
                                    nombre: res.data.name + " " + res.data.last_name + " " + res.data.m_last_name,
                                    cancelar:  ()=>{
                                    // cancelar
                                        this.$router.push("/checkTicket")
                                    },
                                    completed: ()=>{
                                        openModal(ModalWarning, {
                                            title: "Confirmación",
                                            data: "Los datos se actualizaron correctamente",
                                            actualizados: true,
                                            updated: () => {
                                                this.$router.push("/checkTicket");
                                            },
                                        });
                                    }
                                })
                            }
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            if(error.response.status==401){
                                this.salir()
                            }
                            openModal(ModalWarning, {
                                title: "Falló la actualizacion de los datos",
                                data: error,
                            });
                        })
                        .finally(() => {});

                    } else {
                        this.isLoading = false
                        this.showMsg("Atención", "Lo sentimos. La fotografía/selfie no coincide con tu identificación oficial. Favor de tomarla nuevamente e intentarlo de nuevo. Si el problema persiste, por favor repórtalo a ayuda@miseleccion.mx")
                    }
                }).catch(err=>{
                    console.log(err)
                    this.isLoading = false
                    if(err.response.status==500){
                        this.showMsg("Atención", "Lo sentimos. La fotografía de tu identificación oficial no cumple con los requisitos. Favor de tomarla una vez más e intentarlo de nuevo.")
                    } else {
                        this.showMsg("Atención", "Lo sentimos. La fotografía/selfie no coincide con tu identificación oficial. Favor de tomarla nuevamente e intentarlo de nuevo. Si el problema persiste, por favor repórtalo a ayuda@miseleccion.mx")
                    }
                })

            }
            
        },
        showModalExp(userT, emailT, similarity, url_match){
            this.isLoading = false
            openModal(ModalShowExp,{
                title:"Atención",
                //data: "Por favor localice al Coordinador de la Mesa de Ayuda.",
                data: "De acuerdo a la información ingresada, nuestros registros indican que en un partido previo incumpliste con el “Código de Comportamiento en el Estadio”. Por lo anterior, tu registro no puede ser completado y tu acceso al estadio no será permitido.",
                msgs: this.msgsExpl,
                rutaImgExpulsado: this.rutaImgExpulsado, 
                name: userT,
                email: emailT,
                similarity: similarity,
                url_match: url_match,
                continuar:  ()=>{
                    console.log("Continuar con el registro.")
                    //this.isLoading = true
                    //this.continuaRegistro(nameIdnt, nameSelfie)
                },
                cancelar: ()=>{
                    console.log("Cancelar registro.")
                }
            })
        },
        showMsg(psTitle, msg){
            openModal(ModalWarning,{
                title: psTitle,
                data: msg,
                borrarReg : false
            });
        },
        validateForm() {
            var numTel = this.form.mobile.toString().length;
            var validation = !(numTel == 10);
            if (numTel > 0 && validation) {
                this.showMsg("Falta Información", "Registra un número de celular válido a 10 dígitos")
                return false
            } else if (this.menorEdad && this.form.nameTutor.length < 5) {
                this.showMsg("Falta Información","Debe capturar el nombre completo con apellidos del responsable del menor")
                return false
            } else if(this.form.name === "" || this.form.last_name === ""){
                this.showMsg("Falta Información", "Registra el nombre y apellido paterno correctamente")
                return false
            } else if(this.form.photo.length == 0 || this.form.ine.length == 0){
                this.showMsg("Falta Información", "Tome las fotos correctamente")
                return false;
            } else if(this.selectedMonth == -1 || this.selectedYear==0){
                this.showMsg("Falta Información", "Ingresa la fecha de nacimiento correctamente")
                return false
            } else{
                return true;
            }           
        },
        compress(file) {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: 0.6,
                    success(result) {
                        resolve(result);
                    },
                    error(err) {
                        reject(err);
                    },
                });
            });
        },
    },
    mounted() {
        this.form.barCodeId = this.ticket;
        this.validateTicket(this.form.barCodeId);
        if (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)
        ) {
            this.isMobil = true;
        }

        let hoy = new Date();
        let yearInicio = hoy.getFullYear();
        let yearHasta = yearInicio - 90;
        for (let i = yearInicio; i > yearHasta; i--) {
            this.years.push(i);
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.patternback {
    background-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 0.3),
            #00000057
        ),
        url(https://lmxwebsite.s3.amazonaws.com/images/patron-1.png);
    height: 350px;
}
</style>
