import axios from 'axios';

//const API_URL_TENT = 'https://dev-estadio.apilmx.com/' 
const API_URL_TENT = 'https://estadio.apilmx.com/'
class AuthService {
    login(user) {
        return axios
        .post(API_URL_TENT + 'login', {
            username: user.username,
            password: user.password
        })
        .then(response => {
            //console.log("Token: " + response.data.token)
            if (response.data.token) {
            localStorage.setItem('user', JSON.stringify({usuario: this.usuario, accessToken: response.data.token, rol: response.data.rol, user: response.data.user}))
            }
            return response.data;
        })
        .catch((e)=>{
            return e.toString();
        })
    }

    logout() {
        localStorage.removeItem('user')
    }

}

export default new AuthService();