import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import "bootstrap/dist/css/bootstrap.css"
import VueCryptojs from 'vue-cryptojs'
import VueHtmlToPaper from 'vue-html-to-paper'
import VuePictureSwipe from 'vue3-picture-swipe';


createApp(App).use(router).use(VueCryptojs).component('vue-picture-swipe', VuePictureSwipe).use(VueHtmlToPaper).mount('#app')

import "bootstrap/dist/js/bootstrap.js"
