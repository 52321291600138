import { createWebHistory, createRouter } from "vue-router";
import Login from "./components/Login.vue";
import Home from "./components/Home.vue";
import CheckStadium from "./components/CheckStadium.vue";
import CheckTicket from "./components/CheckTicket.vue";
import RegisterTicket from "./components/RegisterTicket.vue";
import EditTickets from "./components/EditTickets.vue";
import ScanQR from './components/ScanQR.vue';
import Galeria from './components/Galeria.vue';

const routes = [
  {
		path: '/login',
		name: 'Login',
		component: Login,
		props: true,
    meta: { authorize: [] }
	}, 
  {
		path: '/',
		name: 'Home',
		component: Home,
    props: true,
    meta: { authorize: [] }
	},
  {
		path: '/checkTicket',
		name: 'checkTicket',
		component: CheckTicket,
		props: true,
    meta: { authorize: ['carpa'] }
	},
  {
		path: '/checkTicket/:numeroBoleto',
		name: 'checkTicketParams',
		component: CheckTicket,
		props: true,
    meta: { authorize: ['carpa'] }
	},
  {
		path: '/registerTicket',
		name: 'registerTicket',
		component: RegisterTicket,
		props: true,
    meta: { authorize: ['carpa'] }
	},
  {
		path: '/edit/:ticket',
		name: 'Editar Datos',
		component: EditTickets,
		props: true,
    meta: { authorize: ['carpa'] }
	},
	{
		path: '/validate/:ticket',
		name: 'validate',
		component: CheckStadium,
		props: true,
    meta: { authorize: ['estadio'] }
	},
	{
		path: '/scan',
		name: 'ScanQR',
		component: ScanQR,
    meta: { authorize: ['estadio'] }
	},
  {
		path: '/galeria',
		name: 'Galeria',
		component: Galeria,
    meta: { authorize: [] }
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  const currentUser = JSON.parse(localStorage.getItem('user'))
  if (!currentUser) {
    // not logged in so redirect to login page
    if(to.path=='/login'){
      next();
    } else {
      next({ path: '/login' })
    }
  } else {
    if (authorize) {
        //Si es admin pasa a donde quiera
        if(currentUser.rol=='admin'){
          next()
        } else if (authorize.length && authorize.includes(currentUser.rol)) { // si es ruta de carpa o estadio vamos a ver si esta en el rol del usuario para que continue
            // dependiendo el rol avanza a sus rutas correspondientes
            if(currentUser.rol=='carpa'){      
              next();
            } else if(currentUser.rol=='estadio'){      
              next();
            }
        } else {
          // si la ruta es / lo mandamos dependiendo su rol a su correspondiente apartado
          if(to.path=='/'){
            if(currentUser.rol=='carpa'){      
              next({ path: '/checkTicket' })
            } else if(currentUser.rol=='estadio'){      
              next({ path: '/scan' })
            }
          } else {
            // en otro caso si la ruta no pertenece a carpa o estadio hacemos redireccionamiento para forzar ir a su apartado correcto
            next('/')
          }
        }
    } else {
      next('/')
    }
  }
});

export default router;