<template>
  <mainHeader></mainHeader>
  <div class="container" style="text-align: center;">
    <loading
            v-model:active="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            :width="150"
            color="#02b348"
            loader="dots"
        />
    <div class="style-caja" v-if="statusOK">
      <br>
      <div class="row">
        <div class="col-md-6">
          <img v-bind:src="user.photo"  style="border-radius: 20px; border:solid; max-height:500px">
        </div>
        <div class="col-md-6">
          <br>
          <br>
          <br>
          <br>
          <br>
          <div class="col-sm-12">
            <h1 class="form-label mollen-font">{{user.name}} {{user.last_name}} {{user.m_last_name}}</h1>
          </div>
          <div class="row"  v-if="user.isChild">  
            <div class="col-sm-4 col-md-4" style="text-align:center">
              <h3 class="form-label mollen-font" style="color:#00943a;" for="menor" v-if="user.isChild">*Menor de edad</h3>
            </div>
            <div class="col-sm-8 col-md-8" style="text-align:center">
              <h3 class="form-label mollen-font" ><b>Tutor: </b>{{user.nameTutor}}</h3>
            </div>
          </div>
          <!-- <div class="col">
            <h3 class="form-label mollen-font" ><b>Boleto: </b><span style="letter-spacing: 7pt;">{{user.barcode}}</span></h3>
          </div> -->
          <br>
          <br>
          <div class="row">
            <div class="col">
              <img :src="require('@/assets/reject.png')" class="img-btn pointer" height="100" width="100" @click="reset()"/>
            </div>
            <div class="col">
              <img :src="require('@/assets/ok.png')" class="img-btn pointer" height="100" v-on:keyup.enter="saveStatus()" width="100" @click="saveStatus()"/>
            </div>
          </div>
        </div>

      </div>
      <!-- <div class="row">
        <div class="col-sm-12">
          <h1 class="form-label mollen-font">{{user.name}} {{user.last_name}} {{user.m_last_name}}</h1>
        </div>
      </div>
      <br>
      <div class="row"  v-if="user.isChild">  
        <div class="col-sm-4 col-md-4" style="text-align:center">
          <h3 class="form-label mollen-font" style="color:#00943a;" for="menor" v-if="user.isChild">*Menor de edad</h3>
        </div>
        <div class="col-sm-8 col-md-8" style="text-align:center">
          <h3 class="form-label mollen-font" ><b>Tutor: </b>{{user.nameTutor}}</h3>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <img v-bind:src="user.photo"  style="border-radius: 20px; border:solid;" width="300">
        </div>
        <div class="col-sm-12 col-md-6">
          <img v-bind:src="user.ine"  style="border-radius: 20px; border:solid;" width="300">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col">
          <h1 class="form-label mollen-font" ><b>Boleto: </b><span style="letter-spacing: 7pt;">{{user.barcode}}</span></h1>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col">
          <img :src="require('@/assets/reject.png')" class="img-btn" height="100" width="100" @click="reset()"/>
        </div>
        <div class="col">
          <img :src="require('@/assets/ok.png')" class="img-btn" height="100" width="100" @click="saveStatus()"/>
        </div>
      </div> -->
    </div>
    <div class="style-caja" v-else>
      <h1 class="form-label mollen-font">{{error}}</h1>
      <button @click="reset" class="btn btn-success m-4 mollen-font">Atrás</button>
    </div>
    <cerrarSesion/>
  </div>
</template>
<script>
import { openModal } from 'jenesius-vue-modal';
// import ModalConfirmVue from './ModalConfirm.vue';
import ModalWarningVue from './ModalWarning.vue';
import authService from  "../services/AuthService"
import dataService from '../services/DataService';
import mainHeader from '../components/headerSmall'
import cerrarSesion from '../components/cerrarSesion'
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
    name: "ValidateTickets",
    props:{
      ticket: String,
    },
    data() {
      return {
        error:"",
        fullPage: true,
        isMobile : false,
        statusOK:false,
        isLoading : false,
        user:{
          barcode:"",
          name:"",
          last_name:"",
          m_last_name:"",
          email:"",
          nameTutor:"",
          isChild:false,
          photo:"",
          ine:"",
        }
      };
    },
    methods: {
      salir(){
        authService.logout()
        this.$router.go()
      },
      async validateTicket(){
        this.isLoading = true;
        dataService.getCheckCA(this.ticket)
        .then(response => {
            if(response.status == 200){
              var data = response.data;
              if (data !== null && data !== undefined) {
                this.user.nameTutor = data.tutor;
                this.user.barcode = data.barCodeId;
                this.user.email = data.email;
                this.user.isChild = data.isChild;
                this.user.name = data.name;
                this.user.last_name = data.last_name;
                this.user.m_last_name = data.m_last_name;
                this.user.ine = data.ine;
                this.user.photo = data.photo;
                this.statusOK = true;
                this.isLoading = false;
              }
            }
        })
        .catch(e => {
            if(e.response.status==401){
              this.salir()
            } else 
            if(e.response.status == 400){
              this.statusOK = false;
              this.isLoading = false;
              this.error = e.response.data.message;
              openModal(ModalWarningVue,{
                title:"Boleto registrado",
                data:e.response.data.message
              });
            }
            if(e.response.status == 404){
              this.statusOK = false;
              this.isLoading = false;
              this.error = e.response.data.message;
              openModal(ModalWarningVue,{
                title:"Boleto no registrado",
                data:e.response.data.message
              });
            }
        })
      },
      onCancel() {
        console.log("User cancelled the loader.");
      },
      async getData() {
      this.isLoading = true;
      var data = null;
      dataService.getScanCA(this.ticket)
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            data = response.data;
          }
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
          if(e.response.status==401){
            this.salir()
          }
        });
      if (data !== null && data !== undefined) {
        this.user.nameTutor = data.tutor;
        this.user.barcode = data.barCodeId;
        this.user.email = data.email;
        this.user.isChild = data.isChild;
        this.user.name = data.name;
        this.user.last_name = data.last_name;
        this.user.m_last_name = data.m_last_name;
        this.user.ine = data.ine;
        this.user.photo = data.photo;
      }
    },
      saveStatus(){
        dataService.setValidCA(this.ticket).then(
          this.$router.replace(({ path:'/scan' }))
        ).catch((e)=>{
          if(e.response.status==401){
            this.salir()
          }
        });
        // openModal(ModalConfirmVue,{
        //   title: "Confirmación",
        //   data: "Esta seguro que desea realizar esta acción",
        //   borrar:  ()=>{
        //     dataService.setValidCA(this.ticket).then(
        //       this.$router.replace(({ path:'/scan' }))
        //     ).catch((e)=>{
        //       if(e.response.status==401){
        //         this.salir()
        //       }
        //     });
        //   },
        //   cancelar:  ()=>{
        //     this.$router.replace(({ path:'/scan' }));
        //   }
        // });
      },
      reset(){
        this.$router.replace(({ path:'/scan' }));
      },
      isMobilDevice(){
        return ( navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)
        )
      }
    },
    mounted(){
      this.validateTicket();
    },
    components: {
        Loading,mainHeader,cerrarSesion
    },
};
</script>
<style scoped>

</style>
