<template>
  <mainHeader></mainHeader>
  <div class="container" style="text-align: center;">
    <form v-on:submit.prevent="submitForm" class="style-caja">
      
      <h4>Control de acceso</h4>
      <div class="row" style="text-align:center; display:none;">
        <div>
          <button class="btn btn-success mollen-font" @click="()=>{this.validarQR=true}">Validar QR</button>
        </div>
      </div>
      <div class="col-sm-12" style="background-color: gainsboro">
        <StreamBarcodeReader
          @decode="(a) => onDecode(a)"
          @loaded="() => onLoaded()"
          v-if="validarQR"
        ></StreamBarcodeReader>
      </div>
      
      <div class="row" style="text-align:center; padding-top:20px;">
          <div class="col align-self-start" style="margin-top:7px; text-align:right">
            <!-- <div><b>Captura manual:</b></div> -->
          </div>
          <div class="col align-self-center">
            <input class="form-control form-control-sm" type="text" v-model="barcode" name="barcodeReader" id="barcodeReader" maxlength="128" @change="codeValid" autofocus>
          </div>
          <div class="col " style="text-align: left;">
            <button class="btn btn-success mollen-font" @click="codeValid">Buscar</button>
          </div>
      </div>
      <!--button @click="next" class="btn btn-success m-4 mollen-font">Siguiente</button-->
    </form>
    <cerrarSesion/>
    
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import {openModal} from "jenesius-vue-modal";
import AlertModalVue from './ModalWarning.vue';
import authService from  "../services/AuthService"
import mainHeader from '../components/headerSmall'
import DataService from '../services/DataService';
import detectaUsuarios from '../services/DetectaUsuarios'
import ModalShowExp from "./ModalShowExp.vue"
import cerrarSesion from '../components/cerrarSesion'

export default {
    name:"ScanQR",
    components: {
      StreamBarcodeReader,mainHeader, cerrarSesion
    },
    data() {
      return {
        text: "",
        id: null,
        validarQR: true,
        barcode:""
      };
    },
    methods:{
      codeValid(){
        var code = this.barcode;
        if(code.length == 128){
          this.verify(code)
        } else {
            openModal(AlertModalVue,{
                title:"Atención",
                data: 'Número de boleto que proporcionó es incorrecto'
            });
        }
        this.barcode = ""
        this.validarQR = true;
      },
      salir(){
        authService.logout()
        this.$router.go()
      },
      onDecode(a) {
        this.validarQR=false
        this.barcode = a;
        this.verify(a);
      },
      onLoaded() {
        console.log("load");
      },
      async verify(code){
        await DataService.getCheckCA(code)
        .then(response => {
            this.validarQR=false
            if(response.status == 200){
                let userT = response.data.name+' '+response.data.last_name
                let emailT = response.data.email
                let resp = detectaUsuarios.getUserDetected(userT, emailT)
                //console.log(resp)
                if(resp.length>0 && resp[0].value==true){
                    openModal(ModalShowExp,{
                        title:"Atención",
                        data: 'De acuerdo a la información ingresada, nuestros registros indican que en un partido previo incumpliste con el “Código de Comportamiento en el Estadio”. Por lo anterior, tu registro no puede ser completado y tu acceso al estadio no será permitido.',
                        msgs: [],
                        name: userT,
                        email: emailT,
                        similarity: "",
                        url_match: "",
                        continuar:  ()=>{
                            console.log("YA NO Continuar con el proceso.")
                        },
                        cancelar: ()=>{
                            console.log("Cancelar registro.")
                            this.barcode = ""
                            this.validarQR = true;
                        }
                    });
                } else {
                    this.$router.replace({ name: 'validate', params: { ticket: code } })
                }
            }
        })
        .catch(e => {
            this.validarQR=false
            if(e.response.status==401){
                this.salir()
            }
            openModal(AlertModalVue,{
                title:"Lectura de QR",
                data: e.response.data.message.replace(/boleto/g,'QR'),
                newReg:false,
                borrarReg:false
            });
            this.barcode = ""
            this.validarQR = true;
        })
      },
    },
    mounted(){
      document.getElementById("barcodeReader").focus();
    }
}
</script>

<style>

</style>