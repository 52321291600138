<template>
    <mainHeader></mainHeader>
    <div class="container" style="text-align: left; margin-top: 25px;">
        <loading
            v-model:active="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            :width="150"
            color="#02b348"
            loader="dots"
        />
        <form class="style-caja" @submit.prevent="buscarBoleto">
            <div class="row form-Margin">
                <div class="row" style="text-align:center">
                    <h3>Mesa de ayuda</h3>
                </div>
                <br><br>
                <div v-if="inputCode">
                    <div class="row">
                        <div class="col-lg-8 col-md-6 col-sm-12">
                            <label for="ticket" class="bold-mollen">Buscar boleto:</label> 
                            <input class="form-control" name="ticket" id="ticket" v-model="idBarcode" oninput="this.value=this.value.replace(/[^0-9]+/, '');" 
                                pattern="\d*" maxlength="22" type="tel" placeholder="Ingrese el Número de Boleto" ref="inputBarCode">
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-6" style="text-align: center; padding-top:2%; display: table;">
                            <div style="display: table-cell; vertical-align: bottom;">
                                <button type="button" class="btn btn-success mollen-font" 
                                    style="background-color: #00852a;" @click="buscarBoleto">Buscar</button>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-6" style="text-align: center; padding-top:2%; display: table;">
                            <div style="display: table-cell; vertical-align: bottom;">
                                <button type="button" class="btn btn-success mollen-font" style="background-color: #02403b;" @click="capturarBoleto" >Capturar</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 mt-20" style="text-align:center; margin-top:10px">
                        <button type="button" @click="scan()" class="btn btn-success mb-3 mollen-font">Escanea tu boleto</button>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 mb-3" style="text-align:center" v-else>
                    <div class="col-sm-12 mt-15">
                        <h3>Escanear boleto</h3>
                        <p class="mt-20">
                            Si tu dispositivo presenta problemas para leer el boleto, por favor ingresa el número de forma manual.
                        </p>
                    </div>
                    <div class="mt-20">
                        <StreamBarcodeReader
                                @decode="(a) => onDecode(a)"
                                @loaded="() => onLoaded()" v-if="loadingStream" >
                        </StreamBarcodeReader>
                    </div>
                    <div class="col-sm-12 mt-20">
                        <button type="button" @click="textManual()" v-if="!inputCode" class="btn btn-success mb-3 mollen-font" style="background-color: #00852a;">Capturar</button>
                    </div>
                </div>

                <div v-if="completed">
                    <div class="row">
                        <div class="col-sm-12">
                            <br/><button type="button" class="btn btn-info mb-3 mollen-font" @click="limpiar">Limpiar</button><br/>
                            <label for=""><b>Resultados:</b></label>
                        </div>
                    </div>
                    <div class="row search style-caja" v-if="boletoRegistrado">
                        <div class="col-lg-4 col-md-4 col-sm-12" style="text-align: center">
                            <h5>Boleto</h5>
                            <span class="ticket-space">{{datosBoleto.barCodeId}}</span> <br>
                            <div style="text-align:center" v-if="reimprimir">
                                <span style="color:green" v-if="datosBoleto.isValid">Autorizado</span>  
                            </div>
                            <div style="text-align:center" v-else>
                                <span style="color:green" v-if="datosBoleto.emailConfirmed">Confirmado</span>
                                <span style="color:red" v-else>No confirmado</span>
                            </div>
                        </div>
                        <br>
                        <div class="col-lg-4 col-md-4 col-sm-12" style="text-align: center">
                            <h5>Datos</h5>
                            {{datosBoleto.name + ' ' + datosBoleto.last_name + ' ' + datosBoleto.m_last_name}} <br>
                            <b>email: </b>{{datosBoleto.email}}
                            <br /><br />
                            <div class="row">
                                <div class="col-lg-6"><span><b>Identificación</b></span> </div>
                                <div class="col-lg-6"><span><b>Selfie</b></span></div>
                            </div>
                            <img :src="datosBoleto.ine" alt="Identificación" style="cursor:pointer;width:50%" @click="visualizar">
                            <img :src="datosBoleto.photo" alt="Selfie" style="cursor:pointer;width:50%" @click="visualizar">
                        </div>
                        <br>
                        <div class="col-lg-4 col-md-4 col-sm-12" style="text-align: center" v-if="!expulsadoDetectado">
                            <h5>Opciones</h5>
                            
                            <div style="text-align:center" v-if="reimprimir">
                                <button type="button" class="btn btn-success mb-3 mollen-font" @click="visualizar"> Visualizar </button><br>
                                <div v-if="!datosBoleto.ticket && user.rol!='admin'">
                                    <button type="button" class="btn btn-warning mb-3 mollen-font" @click="updateTicket"> Editar </button>
                                </div>
                                <div v-show="false"><button type="button" class="btn btn-danger mb-3 mollen-font" @click="deleteTicket"> Borrar </button><br></div>
                                <button type="button" class="btn btn-info mb-3 mollen-font" @click="imprimirQR" v-if="user.rol!='admin'"> 
                                    Re imprimir código QR
                                </button>
                            </div>

                            <div style="text-align:center" v-else>
                                <button type="button" class="btn btn-success mb-3 mollen-font" @click="visualizar"> Visualizar </button><br>
                                <button type="button" class="btn btn-warning mb-3 mollen-font" @click="updateTicket"> Editar </button><br>
                                <button type="button" class="btn btn-danger mb-3 mollen-font" @click="deleteTicket"> Borrar </button><br>
                                <button type="button" class="btn btn-info mb-3 mollen-font" @click="imprimirQR"> 
                                    <!-- {{user.rol=='admin' ? 'Generar código QR': 'Imprimir código QR'}} -->
                                    Checar Acceso Entrada
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row search style-caja" v-else>
                        <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center">
                            <p style="color: red; font-weight: 500">{{msjBusqueda}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { StreamBarcodeReader } from "vue-barcode-reader";
import 'vue-loading-overlay/dist/vue-loading.css';
import authService from  "../services/AuthService"
import dataService from '../services/DataService'
import detectaUsuarios from '../services/DetectaUsuarios'
import {openModal} from "jenesius-vue-modal";
import mainHeader from '../components/header'
import ModalConfirm from "./ModalConfirm.vue";
import ModalWarning from "./ModalWarning.vue";
//import ModalPrintQR from "./ModalPrintQR.vue";
import ModalShowExp from "./ModalShowExp.vue"
import ModalPreviewImg from "./ModalPreviewImg.vue";

export default {
    name: 'CheckTicket',
    props: {
        numeroBoletoProps: String
    },
    components: {
        Loading, StreamBarcodeReader, mainHeader
    },
    data() {
        return {
            user: null,
            fullPage: true,
            isLoading: false,
            completed: false, 
            datosBoleto: null, 
            boletoRegistrado: false, 
            idBarcode: '',
            msjBusqueda: '',
            reimprimir: false,
            inputCode: true,
            loadingStream: false,
            expulsadoDetectado: false
        }
    },
    methods:{
        salir(){
            authService.logout()
            this.$router.go()
        },
        textManual(){
            this.inputCode = true
        },
        scan(){
            this.inputCode = false
            this.loadingStream = true
            this.idBarcode = ''
        },
        async onDecode(a) {
            if(this.idBarcode !== a){
                this.loadingStream = false
                this.inputCode = true
                this.idBarcode = a
                this.buscarBoleto()
            }
        },
        onLoaded() {
            console.log("load");
        },
        visualizar(){
            openModal(
                ModalPreviewImg,{
                datosBoleto: this.datosBoleto,
                aceptar:  ()=>{
                    // cancelar
                }
            })
        },
        limpiar(){
            this.datosBoleto = null
            this.boletoRegistrado = false
            this.idBarcode=""
            this.completed = false
            this.msjBusqueda = ''
        },
        imprimirQR(){
            /* * * * * * * * * * * * * * F E M E N I L * * * * * * * * * * * * * * * */
            this.isLoading = true
            // Vamos a validar este boleto DIRECTO SOLO PARA FEMENIL
            dataService.setValid(this.$CryptoJS.SHA512(this.datosBoleto.barCodeId).toString())
            .then(()=>{
                //console.log(resp.data)
                this.isLoading = false
                openModal(ModalWarning,{title: "Boleto autorizado", data: "Este boleto fue autorizado. El registro fue cerrado."})
                this.limpiar()
            })
            .catch(()=>{
                //console.log(e)
                this.isLoading = false
            });
            /* * * * * * * * * * * * * * F E M E N I L * * * * * * * * * * * * * * * */
            
            // IMPORTANTE. DESCOMENTAR DESPUES DEL DE FEMENIL
            /*
            openModal(
                ModalPrintQR,{
                imagenQr: this.datosBoleto.ticket, 
                numeroBoleto: this.datosBoleto.barCodeId,
                nombre: this.datosBoleto.name + ' ' + this.datosBoleto.last_name + ' ' + this.datosBoleto.m_last_name,
                cancelar:  ()=>{
                    // cancelar
                },
                completed: ()=>{
                    if(this.user.rol=='admin'){
                        openModal(ModalWarning,{title: "Boleto autorizado", data: "Este boleto fue autorizado. El registro fue cerrado."})
                    }
                    this.limpiar()
                }
            })
            */
        },
        updateTicket(){
            //console.log("update " + this.datosBoleto.barCodeId)
            this.$router.push('/edit/'+this.$CryptoJS.SHA512(this.datosBoleto.barCodeId).toString())
        },
        deleteTicket(){
            let self = this
            openModal(
                ModalConfirm,
                {
                title: "Eliminar registro del boleto",
                data: `¿Está seguro de eliminar el registro del boleto ${self.datosBoleto.barCodeId}?`,
                borrar:  ()=>{
                    this.isLoading = true
                    dataService.getDelete(self.$CryptoJS.SHA512(self.datosBoleto.barCodeId).toString())
                    .then(()=>{
                    //console.log(resp)
                    this.isLoading = false
                    self.limpiar()
                    openModal(ModalWarning,{title: "Registro eliminado", data: ""})
                    })
                    .catch((e)=>{
                    console.log(e.response)
                    this.isLoading = false
                    if(e.response.status==401){
                        self.salir()
                    } else {
                        openModal(ModalWarning,{title: e.response.data.message, data: ""})
                        self.limpiar()
                    }
                    })
                },
                cancelar:  ()=>{
                    //this.idBarcode = ""
                }
                }
            )
        },
        onCancel() {
            this.isLoading = false
        },
        buscarBoleto(e){
            if(e) e.preventDefault();
            this.completed = false
            this.reimprimir = false
            this.expulsadoDetectado = false
            if( !(this.idBarcode.length==14 || this.idBarcode.length==18 || this.idBarcode.length==22) ){
                this.boletoRegistrado = false
                this.completed = true
                this.msjBusqueda = 'Número de boleto que proporcionó es incorrecto'
            } else {
                this.isLoading = true
                dataService.getCheck(this.$CryptoJS.SHA512(this.idBarcode).toString())
                .then((resp)=>{
                    //console.log(resp)
                    this.isLoading = false
                    this.completed = true
                    if(resp.status==200 && !resp.data.message){
                        let userT = resp.data.name+ ' ' + resp.data.last_name
                        let emailT = resp.data.email
                        let respD = detectaUsuarios.getUserDetected(userT, emailT)
                        console.log(respD)
                        if(respD.length>0 && respD[0].value){
                            //this.completed = false
                            var msgsEx = []
                            respD.forEach(msg=>{
                                msgsEx.push(msg.msg)
                            })
                            openModal(ModalShowExp,{
                                title:"Atención",
                                data: "De acuerdo a la información ingresada, nuestros registros indican que en un partido previo incumpliste con el “Código de Comportamiento en el Estadio”. Por lo anterior, tu registro no puede ser completado y tu acceso al estadio no será permitido.",
                                msgs: msgsEx,
                                name: userT,
                                email: emailT,
                                similarity: "",
                                url_match: "",
                                continuar:  ()=>{
                                    console.log("Continuar con el proceso.")
                                    console.log(resp.data)
                                    this.boletoRegistrado = true
                                    this.datosBoleto = resp.data
                                },
                                cancelar: ()=>{
                                    console.log("Cancelar registro.")
                                }
                            })
                            this.expulsadoDetectado = true
                        } // else {
                            this.boletoRegistrado = true
                            this.datosBoleto = resp.data
                        //}
                        //this.msjBusqueda = e.response.data.message
                    } else {
                        this.boletoRegistrado = false
                        this.idBarcode=""
                        this.msjBusqueda = resp.data.message
                    }
                })
                .catch((e)=>{
                    //console.log(e.response)
                    this.completed = true
                    this.isLoading = false
                    if(e.response.status==400){
                        if(e.response.data && e.response.data.barCodeId){
                            let userT = e.response.data.name+' ' + e.response.data.last_name
                            let emailT = e.response.data.email
                            let respD = detectaUsuarios.getUserDetected(userT, emailT)
                            if(respD.length>0 && respD[0].value){
                                //this.completed = false
                                var msgsEx = []
                                respD.forEach(msg=>{
                                    msgsEx.push(msg.msg)
                                })
                                openModal(ModalShowExp,{
                                    title:"Atención",
                                    data: "De acuerdo a la información ingresada, nuestros registros indican que en un partido previo incumpliste con el “Código de Comportamiento en el Estadio”. Por lo anterior, tu registro no puede ser completado y tu acceso al estadio no será permitido.",
                                    msgs: msgsEx,
                                    name: userT,
                                    email: emailT,
                                    similarity: "",
                                    url_match: "",
                                    continuar:  ()=>{
                                        console.log("Continuar con el proceso.")
                                        this.reimprimir = true
                                        this.boletoRegistrado = true
                                        this.datosBoleto = e.response.data
                                    },
                                    cancelar: ()=>{
                                        console.log("Cancelar registro.")
                                    }
                                })
                                this.expulsadoDetectado = true
                            }// else {
                                this.reimprimir = true
                                this.boletoRegistrado = true
                                this.datosBoleto = e.response.data
                            //}
                        } else {
                            console.log(e.response.data)
                            this.boletoRegistrado = false
                            this.msjBusqueda = e.response.data.message
                        }
                    } else if(e.response.status==404){
                        this.boletoRegistrado = false
                        this.msjBusqueda = e.response.data.message
                    } else if(e.response.status==401){
                        this.salir()
                    } else {
                        this.boletoRegistrado = false
                        this.idBarcode=""
                    }
                })
            }
        },
        capturarBoleto(){
            //console.log("Vamos a capturar el boleto...")
            this.$router.push("/registerTicket")
        }
    },
    mounted(){
        //console.log("Vamos a consultar un service")
        this.user = JSON.parse(localStorage.getItem('user'))
        if(this.numeroBoletoProps){
        this.idBarcode = this.numeroBoletoProps
        this.buscarBoleto()
        }
        this.$refs.inputBarCode.focus()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
