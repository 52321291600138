<template>
  <router-link to="/">
        <img alt="Vue logo" src="@/assets/header-page-fmf.jpg" class="marginImg img-fluid">
  </router-link>
  <div class="row" v-if="user">
    <div style="text-align:right">
      <button class="btn btn-danger mollen-font" style="margin-right:75px" @click="salir">Cerrar sesión</button>
    </div>
    <h5>Para regresar al Menú Principal, haga clic sobre el logotipo de la Selección Nacional</h5>
  </div>
</template>

<script>

import authService from  "/src/services/AuthService"
export default {
    name:"mainHeader",
    components: {
    },
    data() {
      return {
        user: null
      }
    },
    methods:{
      salir(){
        authService.logout()
          this.$router.go()
      },
    },
    mounted(){
      this.user = localStorage.getItem('user')
      if(this.user && location.pathname=='/index.html'){
        this.$router.push("/")
      }
    }
}
</script>

<style>

</style>