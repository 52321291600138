<template>

  <mainHeader></mainHeader>
  <div class="container" style="text-align: left; margin-top: 25px;">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      :width="150"
      color="#02b348"
      loader="dots"
    />
    <div class="style-caja">
      <div class="col-sm-20" style="text-align: center;">
        <h1 class="form-label mollen-font">Acceso al sistema</h1>
      </div>
      <Form @submit="handleLogin" :validation-schema="schema">
        <br/>
        <div class="form-group">
          <label for="username" class="bold-mollen">Usuario:</label>
          <Field name="username" type="text" class="form-control" v-model="usuario"/>
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        <br/>
        <div class="form-group">
          <label for="password" class="bold-mollen">Contraseña:</label>
          <Field name="password" type="password" class="form-control"  v-model="password"/>
          <ErrorMessage name="password" class="error-feedback" />
        </div>

        <div class="form-group" style="text-align:right;">
          <br/>
          <button class="btn btn-success btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span class="mollen-font">Login</span>
          </button>
        </div>

        <div class="form-group">
          <div v-if="message.length>0" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
      </div>
      <br/>
    </div>
    
  <br>  <br>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import authService from '../services/AuthService'
import mainHeader from '../components/header'

export default {
  name: "Login",
  props: {
    msg: String,
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Loading,
    mainHeader
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("El usuario es requerido!"),
      password: yup.string().required("La contraseña es requerida!"),
    });
    return {
      fullPage: true,
      isLoading: false,
      usuario: "",
      password: "",
      loading: false,
      message: "",
      schema,
    };
  },
  methods: {
    onCancel() {
      this.isLoading = false
    },
    handleLogin() {
      /* REALIZAR LA CONSULTA DE LOGIN, SI EL LOGIN ES CORRECTO PASAR A CheckTicket (SI HUBIERA TIEMPO... REVISAR CON VUEX)*/
      this.isLoading = true
      this.message = ""
      authService.login({"username": this.usuario, "password": this.password}).then((resp)=>{
        this.isLoading = false
        if( typeof(resp) == 'string' && resp.indexOf("Error")>=0){
          this.message = "Usuario o password incorrectos."
        } else {
          this.$router.go()
        }
      })
      .catch((e)=>{
        this.isLoading = false
        console.log(e)
        this.message = "Usuario o password incorrectos."
      })

    },
  },
  mounted(){
    let user = localStorage.getItem('user')
    if(user){
      this.$router.push("/")
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
