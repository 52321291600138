<template>
  <mainHeader></mainHeader>
  <div class="container" style="text-align: left; margin-top: 25px;">
    <br/><br/><br/>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12" style="text-align:center">
        <button class="btn-lg btn-success mb-3" @click="gotoTent">Mesa de ayuda</button>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12" style="text-align:center">
        <button class="btn-lg btn-success mb-3" @click="gotoStadium">Control de acceso</button>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12" style="text-align:center">
        <button class="btn-lg btn-success mb-3" @click="gotoGaleria">Galería de expulsados</button>
      </div>
    </div>
    <br/><br/><br/>
  </div>
</template>

<script>
import mainHeader from '../components/header'
export default {
  name: 'Home',
  data() {
    return {
      datos: [] 
    }
  },
  methods:{
    gotoTent(){
      this.$router.push("/checkTicket")
    },
    gotoStadium(){
      this.$router.push("/scan")
    },
    gotoGaleria(){
      this.$router.push("/galeria")
    }
  },
  components: {mainHeader}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
