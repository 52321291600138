<template>
  <div class="cont">
        <h4>{{title}}</h4>
        <div class="row" v-if="danger">
          <div style="text-align:center">
            <br>
            <img src="../assets/danger.gif" alt="danger" style="width:64px">
          </div>
        </div>
        <br>
        <h5 v-if=" data.indexOf('ayuda@miseleccion.mx')>=0 ">
            {{
                data.replace("ayuda@miseleccion.mx","") 
            }} <a href="mailto:ayuda@miseleccion.mx" target="_blank">ayuda@miseleccion.mx</a>
        </h5>
        <h5 v-else>
            {{data}}
        </h5>
        <br>
        <button type="button" @click="close()" class="btn btn-success mb-3 mollen-font">{{newReg ? 'Ok, salir': 'Aceptar'}}</button>&nbsp;
        <button type="button" @click="newRegistro()" v-if="newReg" class="btn btn-success mb-3">Registrar otro boleto</button>&nbsp;
        <button type="button" @click="confirmChange()" class="btn btn-warning mb-3"  v-if="borrarReg">Cambiar Datos</button>&nbsp;
        <button type="button" @click="confirm()" class="btn btn-danger mb-3" v-if="borrarReg">Borrar Registro</button>
    </div>
</template>

<script>
import {popModal, openModal} from "jenesius-vue-modal";
import ModalConfirm from "./ModalConfirm.vue";
export default {
    name:"ModalWarning",
    props: {
        title:String,
        data:String,
        borrarTicket:Function,
        changeData:Function,
        borrarReg : Boolean,
        newReg : Boolean,
        actualizados : Boolean,
        updated:Function,
        danger: {
            type: Boolean,
            default: false
        },
    },
    methods:{
        close(){
            if(this.newReg){
                window.location.replace("https://miseleccion.mx/");
                popModal();
            } else {
                if(this.actualizados){
                    this.updated();
                }
                popModal();
            }
            
        },
        newRegistro(){
            location.reload();
        },
        confirm(){
            openModal(ModalConfirm,{
                title: "Confirmación",
                data: "Recibirá en el correo que utilizó para registrar su boleto un mensaje para confirmar que desea borrar el Registro. ¿Desea continuar?",
                borrar: () => {
                  this.borrarTicket();
                },
                cancelar: () => {
                  
                }

            })
        },
        confirmChange(){
            openModal(ModalConfirm,{
                title: "Confirmación",
                data: "Recibirá en el correo que utilizó para registrar su boleto un mensaje para confirmar que desea actualizar el Registro. ¿Desea continuar?",
                borrar: () => {
                  this.changeData();
                },
                cancelar: () => {
                  
                }

            })
        }
    }
}
</script>

<style>
    .cont{
        background-color: white;
        border: outset 1px black;
        padding: 5%;
    }
</style>