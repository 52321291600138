<template>
    <div class="cont">
        <h4>{{title}}</h4>
        <h5>{{data}}</h5>
        <br>
        <button type="button" @click="borrar(), popModal()" class="btn btn-success mb-3 mollen-font">Aceptar</button>&nbsp;
        <button type="button" @click="cancelar(), popModal()" class="btn btn-danger mb-3 mollen-font">Cancelar</button>
    </div>
</template>
<script>
import {popModal} from "jenesius-vue-modal";
    export default {
        name: 'Modal',
        props: {
            borrar: Function,
            cancelar : Function,
            title:String,
            data:String
        },
        data(){
            return{
                avisoPriv: false,
            }
        },
        setup(){
            return {
				popModal
            }
        },
    }
</script>
<style>
    .cont{
        background-color: white;
        border: ridge 1px black;
        padding: 2%;
    }
</style>