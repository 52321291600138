<template>
    <div class="cont">
      <form action="imprimir" class="style-caja">
        <div id="printMe" style="height:500px; overflow: scroll;">
          <div class="row printCenter" style="text-align:center;">
              
            <div class="row">
              <div class="col-sm-12">
                <h1 class="form-label mollen-font">{{datosBoleto.name}} {{datosBoleto.last_name}} {{datosBoleto.m_last_name}}</h1>
              </div>
            </div>
            <br>
            <div class="row"  v-if="datosBoleto.isChild || (datosBoleto.tutor && datosBoleto.tutor.length>0)">  
              <div class="col-sm-3" style="text-align:center">
                <h3 class="form-label mollen-font" style="color:#00943a;" for="menor" v-if="datosBoleto.isChild || (datosBoleto.tutor && datosBoleto.tutor.length>0)">Menor de edad *</h3>
                <input type="checkbox" checked disabled>
              </div>
              <div class="col-sm-6" style="text-align:center">
                <h3 class="form-label mollen-font" ><b>Tutor: </b>{{datosBoleto.tutor}}</h3>
              </div>
          </div>

            <br>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6">
                <img v-bind:src="datosBoleto.ine"  style="border-radius: 20px; border:solid;" width="300" height="300">
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <img v-bind:src="datosBoleto.photo"  style="border-radius: 20px; border:solid;" width="300" height="300">
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col">
                <h2 class="form-label mollen-font" style="margin-top: 25px;" >Boleto:&nbsp;&nbsp; <b style="letter-spacing: 0.5rem" >{{datosBoleto.barCodeId}}</b></h2>
              </div>
            </div>
              
            </div>
        </div>
        <br>
        <button type="button" @click="aceptar(), popModal()" class="btn btn-success mb-3 mollen-font">Aceptar</button>
      </form>
    </div>
</template>
<script>

import {popModal} from "jenesius-vue-modal";
    export default {
        name: 'ModalPreviewImg',
        props: {
            aceptar : Function,
            datosBoleto: {
              type: Object, dafault: ()=>{}
            }
        },
        data(){
            return{
                avisoPriv: false,
            }
        },
        setup(){
          return {
            popModal
          }
        },
        methods: {
          async imprimir () {
            //console.log("Imprimiendo QR")
            await this.$htmlToPaper('printMe', null, ()=>{
                this.completed()
            });
          },
          isMobilDevice(){
            return ( navigator.userAgent.match(/Android/i)
              || navigator.userAgent.match(/webOS/i)
              || navigator.userAgent.match(/iPhone/i)
              || navigator.userAgent.match(/iPad/i)
              || navigator.userAgent.match(/iPod/i)
              || navigator.userAgent.match(/BlackBerry/i)
              || navigator.userAgent.match(/Windows Phone/i)
            )
          }
        }
    }
</script>
<style>
    .cont{
        background-color: white;
        border: ridge 1px black;
        padding: 2%;
    }
</style>