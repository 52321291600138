<template>
  <mainHeader></mainHeader>
  <div class="container" style="text-align: center; margin-top: 25px;">
    <br/><br/>
    <h3>Galería de expulsados</h3>
    <br/>
    <!--h3>Vamos a pintar una galería de personitas educadas :3</h3-->
    <div class="row">
      <div class="col">
        <vue-picture-swipe :items="images" :options="{shareEl: false}"></vue-picture-swipe>
      </div>
    </div>
    <br/><br/><br/>
  </div>
</template>

<script>
import mainHeader from '../components/header'
export default {
  name: 'Galeria',
  data() {
    return {
      images: [
        {src: require('@/assets/gallery/DSA8SF_0b35a1725d329d8d8a7e7a2b3f875ce9fa8d25752e8e96d698d5474c30eca45dae8e56b7a3002a1e9fd64a55f77d16dda05b86e6f04a1e466d4c094c7a4ec55e.jpg'), thumbnail: require('@/assets/gallery/DSA8SF_0b35a1725d329d8d8a7e7a2b3f875ce9fa8d25752e8e96d698d5474c30eca45dae8e56b7a3002a1e9fd64a55f77d16dda05b86e6f04a1e466d4c094c7a4ec55e.jpg'), w: 0,h: 0},
        {src: require('@/assets/gallery/DSA8SF_1c043ea66a7940e0d861cefa24e4d1ddf99ededc8c03ffceb2c1aba902e3ba3c7cda55f6eb8c404d416ad0dd285889e7be0f41ce59177c30073d4c68af8b0e78.jpg'), thumbnail: require("@/assets/gallery/DSA8SF_1c043ea66a7940e0d861cefa24e4d1ddf99ededc8c03ffceb2c1aba902e3ba3c7cda55f6eb8c404d416ad0dd285889e7be0f41ce59177c30073d4c68af8b0e78.jpg"), w: 0,h: 0},
        {src: require('@/assets/gallery/DSA8SF_1e29d5b0f40266f469a678d71c45cb4a07f81108c1ee51b028dbb90d91d752742fe56b59754ac2f39caa1db80e7cfc0b3ef1dd7ab4d47ba392c3f68ad65f00e4.jpg'), thumbnail: require("@/assets/gallery/DSA8SF_1e29d5b0f40266f469a678d71c45cb4a07f81108c1ee51b028dbb90d91d752742fe56b59754ac2f39caa1db80e7cfc0b3ef1dd7ab4d47ba392c3f68ad65f00e4.jpg"), w: 0,h: 0},
        {src: require('@/assets/gallery/DSA8SF_2c56c73658808dbc687b057ae4f3cdd2c9a1752740becf9b96ac027874e3353ae1a3f2b66a3227f99fffbe3f15cca45954c3d690304b65d5c60a038f79ad0a9d.jpg'), thumbnail: require("@/assets/gallery/DSA8SF_2c56c73658808dbc687b057ae4f3cdd2c9a1752740becf9b96ac027874e3353ae1a3f2b66a3227f99fffbe3f15cca45954c3d690304b65d5c60a038f79ad0a9d.jpg"), w: 0,h: 0},
        {src: require('@/assets/gallery/DSA8SF_4c38dc7a5027677f63de69654dcedb22217cf5f01d4ec9245df8570a3d4c0d38060ae6c6d47cb269e1942df51eb6179ad6f4d1a82d102797e423d9e7ece6ae0f.jpg'), thumbnail: require("@/assets/gallery/DSA8SF_4c38dc7a5027677f63de69654dcedb22217cf5f01d4ec9245df8570a3d4c0d38060ae6c6d47cb269e1942df51eb6179ad6f4d1a82d102797e423d9e7ece6ae0f.jpg"), w: 0,h: 0},
        {src: require('@/assets/gallery/DSA8SF_60603af830f02b5f2a40f6ccf04173dbbca80822f98d889e569d993087c450c862ace2af73fffda4734219b2aa577bfea99c365e12d465331231f99214f30500.jpg'), thumbnail: require("@/assets/gallery/DSA8SF_60603af830f02b5f2a40f6ccf04173dbbca80822f98d889e569d993087c450c862ace2af73fffda4734219b2aa577bfea99c365e12d465331231f99214f30500.jpg"), w: 0,h: 0},
        {src: require('@/assets/gallery/DSA8SF_0937263351e7e74341e7f0f662a65038d6b5cbb07ece352350abfe59b4f59be4d7bacb3fc9c70497707e9aa4ce0176076bd9dec286a675060008e7b1c87e61c1.jpg'), thumbnail: require("@/assets/gallery/DSA8SF_0937263351e7e74341e7f0f662a65038d6b5cbb07ece352350abfe59b4f59be4d7bacb3fc9c70497707e9aa4ce0176076bd9dec286a675060008e7b1c87e61c1.jpg"), w: 0,h: 0},
        {src: require('@/assets/gallery/DSA8SF_a926f089aad86eb21fb5da797c8f98afd3bd4b34bacaa5b7255846df626fd9e71b0896c8c0e74118a0abf9b5c4f3be508e06e06c7c31dc7f465383d0fd765eb3.jpg'), thumbnail: require("@/assets/gallery/DSA8SF_a926f089aad86eb21fb5da797c8f98afd3bd4b34bacaa5b7255846df626fd9e71b0896c8c0e74118a0abf9b5c4f3be508e06e06c7c31dc7f465383d0fd765eb3.jpg"), w: 0,h: 0},
      ],
      index: 0
    }
  },
  methods:{
    onClick(i) {
      this.index = i;
    }
  },
  mounted(){
    
  },
  components: {mainHeader}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
figure > a > img{
  border-radius: 8px;
  height: 500px;
  width: 500px;
}
</style>
