<template>
    <mainHeader></mainHeader>
    <div class="container" style="text-align: center; margin-top: 25px;">
        <loading v-model:active="isLoading"
                :can-cancel="true"
                :on-cancel="onCancel"
                :is-full-page="fullPage"
                :width="150"
                color="#02b348"
                loader="dots"/>
        <form v-on:submit.prevent="submitForm" class="style-caja">
            <div class="row form-Margin" v-if="inputCode">
                <div class="col-sm-12">
                    <h3 class="mollen-font">¡Hola, Incondicional!</h3>
                    <p class="bold-mollen text-fontp" style="margin-top:20px;">
                        Para iniciar tu registro, debes capturar el número de tu boleto ubicado sobre el código de barras y hacer clic en el botón <b>"Validar"</b>. O bien, escanea tu boleto con el botón correspondiente, colocándolo sobre una superficie plana y utilizando la cámara de tu dispositivo.
                    </p>
                </div>
                <div class="col-sm-11">
                    <input oninput="this.value=this.value.replace(/[^0-9]+/, '');" type="tel" class="form-control" id="barCodeId" name="barCodeId" 
                        placeholder="Ingrese el Número de Boleto" v-model="form.barCodeId" pattern="\d*" maxlength="22" ref="inputBarCodeId" @keyup.enter="verificar($event)">
                </div>
                <div class="col-sm-1">
                    <button type="button" class="btn btn-success mb-3 mollen-font" @click="verificar($event)">Validar</button>
                </div>
                <div class="col-sm-12 mt-20">
                    <br/>
                    <button type="button" @click="scan()" class="btn btn-success mb-3 mollen-font">Escanea tu boleto</button>
                    <br/>
                    <button type="button" @click="cancelarNuevoRegistro(1)" class="btn btn-danger mb-3 mollen-font">Cancelar</button>
                </div>
                <div class="col-sm-12 mt-20 bold-mollen text-fontp">
                    <p>
                        Si tienes dudas sobre el proceso de registro, consulta la siguiente <a href="#">guía</a> o la sección de <a href="#">preguntas frecuentes</a>.
                    </p>
                    <p>
                        <a href="mailto:ayuda@miseleccion.mx" target="_blank">ayuda@miseleccion.mx</a>
                        mail@fmf.mx
                    </p>
                </div>
                
            </div>
            <div class="col-lg-12 mb-3" v-if="loading">
                <div class="col-sm-12 mt-15">
                    <h3>Escanear boleto</h3>
                    <p class="mt-20">
                        Si tu dispositivo presenta problemas para leer el boleto, por favor ingresa el número de forma manual.
                    </p>
                </div>
                <div class="mt-20">
                    <StreamBarcodeReader :key="scanKey"
                            @decode="(a, b, c) => onDecode(a, b, c)"
                            @loaded="() => onLoaded()"
                            @beforeDestroy="() => destroy()" v-if="loading" >
                    </StreamBarcodeReader>
                </div>
                <div class="col-sm-12 mt-20">
                    <button type="button" @click="textManual()" v-if="!inputCode" class="btn btn-success mb-3 mollen-font" style="background-color: #00852a;">Capturar</button>
                </div>
            </div>
            <div class="row form-Margin" v-if="formStatus">
                <div class="mb-3">
                    <h4>Captura de datos</h4>
                    <div class="col-12 mt-15" style="text-align: -webkit-center;">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="text-versus">
                                            VS
                                        </div>
                                        
                                    </td>
                                    <td style="    padding-left: 5px;">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="text-teams">
                                                            {{eventMatch && eventMatch.split('vs')[0].toUpperCase()}}
                                                        </div>
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="text-teams">
                                                            {{eventMatch && eventMatch.split('vs')[1].toUpperCase()}}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <label class="form-label mt-15 ticket-space">
                        <b>Boleto: {{ form.barCodeId || "Nothing" }}</b>
                    </label>
                    <p class="mt-20">
                        Selecciona el <b>tipo de registro</b>. Si quieres dar de alta a un menor de edad, deberás contar con los datos del padre/madre o tutor para poder continuar el proceso.
                    </p>
                    <input type="hidden" id="barCodeId" name="barCodeId" v-model="form.barCodeId">
                    <br>
                    
                    <br/>
                </div>
                <div class="mb-3" v-if="error">
                    <label class="form-label error">
                        <b>Error: {{ textError || "Nothing" }}</b>
                    </label>
                    <input type="hidden" id="barCodeId" name="barCodeId" v-model="form.barCodeId">
                </div>
                <div class="row mb-3" style="text-align:center">  
                    <div class="col-lg-6">
                        <div class="form-check" style="">
                            <input class="form-check-input" type="checkbox" name="menorMayor" id="mayor" v-model="mayorEdad"
                                v-on:change="changeMayor"
                                :checked="mayorEdad">
                            <label class="form-check-label" for="mayor">
                                Adulto
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-check" style="">
                            <input class="form-check-input" type="checkbox" name="menorMayor" v-model="menorEdad"
                                v-on:change="changeMenor"
                                :checked="menorEdad">
                            <label class="form-check-label" for="menor">
                                Menor de edad
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row mb-3" v-if="menorEdad">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="col-sm-12">
                        <label for="inputNombre" class="form-label fontallbold">
                            <span style="color:#00852a;font-weight: 600;font-size: 18px;">* </span>
                            Nombre de la madre, padre o tutor:
                        </label>
                    </div>
                    <div class="col-sm-12">
                        <input type="text" class="form-control" id="inputNombre" name="name" placeholder="" v-model="form.nameTutor" ref="refInputNombreTutor">
                    </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="col-sm-12 fontallbold" style="margin-top:10px">
                            <span style="color:#00852a;font-weight: 600;font-size: 18px;">* </span>
                            Mes y Año de Nacimiento{{menorEdad ? ' del tutor': ''}}
                        </div>
                            
                        <div class="">
                            <select class="form-control c-in-date-m"  name="month" id="month" v-model="selectedMonth">
                            <option style="font-size:12px" :value="-1">
                                Mes
                            </option>
                            <option style="font-size:12px" v-for="(mes, i) in meses" :key="i" :value="i">
                                {{mes}}
                            </option>
                            </select>
                            <span style="width:5%; text-align: center; display: inline-block;"> / </span>
                            <select class="form-control c-in-date-y" name="year" id="year" v-model="selectedYear">
                            <option value="0">
                                Año
                            </option>
                            <option v-for="(year, i) in years" :key="i" :value="year">
                                {{year}}
                            </option>
                            </select>
                        </div>
                        
                    </div>   
                </div>
                <div class="mb-3">
                    <div class="row">
                    <div :class="menorEdad ? 'col-sm-12 col-md-12 col-lg-12':'col-sm-12 col-md-12 col-lg-12'">
                        <div class="row">
                            <div class="col-sm-12 col-md-4">
                                <div class="col-sm-12"><label for="inputNombre" class="form-label fontallbold">
                                    <span style="color:#00852a;font-weight: 600;font-size: 18px;">* </span>
                                    {{menorEdad ? 'Nombre del menor':'Nombre'}}
                                </label></div>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" id="inputNombre" name="name" placeholder="" v-model="form.name" ref="refInputNombre">
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div class="col-sm-12"><label for="inputNombre" class="form-label fontallbold">
                                    <span style="color:#00852a;font-weight: 600;font-size: 18px;">* </span>
                                    {{menorEdad ? 'Apellido paterno del menor':'Apellido paterno'}}
                                </label></div>
                                <div class="col-sm-12"><input type="text" class="form-control" id="inputApellido" name="lastname" placeholder="" v-model="form.last_name"></div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div class="col-sm-12"><label for="inputNombre" class="form-label fontallbold">{{menorEdad ? 'Apellido materno del menor':'Apellido materno'}}</label></div>
                                <div class="col-sm-12"><input type="text" class="form-control" id="inputApellidoM" name="m_last_name" placeholder="" v-model="form.m_last_name"></div>
                            </div>
                        </div>
                    </div>
                    <div  v-if="!menorEdad" class="col-sm-12 col-md-12 col-lg-12">
                        <div class="col-sm-12 fontallbold" style="margin-top:10px;">
                            <span style="color:#00852a;font-weight: 600;font-size: 18px;">* </span>
                            Fecha de Nacimiento
                        </div>
                            <div class="col-sm-12">
                            <div class="center">
                                <select class="form-control c-in-date-m"  name="month" id="month" v-model="selectedMonth">
                                <option style="font-size:12px" :value="-1">
                                    Mes
                                </option>
                                <option style="font-size:12px" v-for="(mes, i) in meses" :key="i" :value="i">
                                    {{mes}}
                                </option>
                                </select>
                                <span style="width:5%; text-align: center; display: inline-block;"> / </span>
                                <select class="form-control c-in-date-y" name="year" id="year" v-model="selectedYear">
                                <option value="0">
                                    Año
                                </option>
                                <option v-for="(year, i) in years" :key="i" :value="year">
                                    {{year}}
                                </option>
                                </select>
                            </div>
                        </div>

                    </div>   
                    </div>
                </div>
                <div class="mb-3">
                    <label for="inputEmail" class="form-label fontallbold">
                        <span style="color:#00852a;font-weight: 600;font-size: 18px;">* </span>
                        Correo electrónico:
                    </label>
                    <input type="email" class="form-control" id="inputEmail" name="email" placeholder="name@example.com" @change="isEmailValid" v-model="form.email">
                    <span v-show="wrongEmail" style="color:red">Correo no válido</span>
                </div>
                <div class="mb-3">
                    <label for="inputMovil" class="form-label fontallbold">Número de celular:</label>
                    <input type="tel" oninput="this.value=this.value.replace(/[^0-9]+/, '');" class="form-control" id="inputMovil" maxlength="10" name="mobile" placeholder="" v-model="form.mobile">
                </div>
                <!--div class="mb-3">
                    <label for="mayorEdad">Soy menor de edad</label>&nbsp;&nbsp;
                    <input type="checkbox" id="mayorEdad" v-model="mayorEdad">
                </div-->


                <!--Campo de la identificacion-->
                <div class="mb-3">
                    <label for="inputINE" class="form-label fontallbold">
                        <span style="color:#00852a;font-weight: 600;font-size: 18px;"></span>
                        Identificación oficial con fotografia{{menorEdad ? ' del tutor': ''}}:</label>
                    <div class="row">
                        <div :class="isMobil?'col-lg-12':'col-lg-11'" v-show="isMobil">
                            <label for="inputINE" class="subir">
                                <i class="fas fa-cloud-upload-alt"></i> 
                                <img src="../assets/icono-camara-gris.png" alt="cámara" style="cursor: pointer; width: 135px"/>
                            </label>
                            <input type="file" accept="image/*" @change="changeImg($event ,'ine')" class="form-control" id="inputINE" name="ine" placeholder="" v-show="false">
                            <img :src="dataUrlIneBase64" alt="selfie" v-if="dataUrlIneBase64.length>0" style="width:100%"/>
                        </div>
                        <div class="col-lg-12" v-if="!isMobil">
                        <img src="../assets/icono-camara-gris.png" alt="cámara" @click="startCameraStream($event, 'ine')" style="cursor: pointer; width: 135px"
                            v-show="!isCameraOpenIne" v-if="dataUrlIneBase64.length==0" />
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-if="!isMobil">
                    <img :src="dataUrlIneBase64" alt="selfie" v-if="dataUrlIneBase64.length>0"/>
                    <div v-show="isCameraOpenIne">
                        <br/>
                        <div style="margin-top: -25px;">
                            <button class="btn btn-danger mb-3 mollen-font" @click="cancelarCaptura($event, 'ine')">Cancelar</button>&nbsp;
                            <button class="btn btn-success mb-3 mollen-font" @click="capture($event, 'ine')" style="background-color: #00852a;">Capturar</button>
                        </div>
                        <div class="camera-box" style="margin-top: 50px">
                            <div class="camera-canvas">
                                <div style="postion: absolute; width: 100%; height: 410px; background-size: 450px 300px; opacity: 0.9; z-index: 300000; background-repeat:no-repeat; background-position: center center; background-image: url('/img/siluetaIne.png');"></div>
                                <video ref="cameraIne" :width="canvasWidth" autoplay style="margin-top:-480px"></video>
                                <canvas v-show="false" id="ineTaken" ref="canvasIne" :width="canvasWidth" :height="canvasHeight"></canvas>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isCameraOpenIne && dataUrlIneBase64.length>0">
                        <br/>
                        <button class="btn btn-info mb-3 mollen-font" @click="changeSelfie($event, 'ine')">Cambiar identificación oficial</button>
                    </div>
                </div>
                <!--FIN Campo de la identificacion-->

                <!--Campo de Selfie-->
                <div class="mb-3" >
                    <label v-if="menorEdad" for="inputFoto" class="form-label mollen-font">Fotografía / Selfie del menor:</label>
                    <label v-else for="inputFoto" class="form-label mollen-font">Fotografía / Selfie:</label>
                    <div class="row">
                        <div :class="isMobil?'col-lg-12':'col-lg-11'" v-show="isMobil">
                            <label for="inputFoto" class="subir">
                                <i class="fas fa-cloud-upload-alt"></i> 
                                <img src="../assets/icono-camara-gris.png" alt="cámara" style="cursor: pointer; width: 135px"/>
                            </label>
                            <input type="file" accept="image/*" @change="changeImg($event ,'selfie')" class="form-control" id="inputFoto" name="photo" placeholder="" v-show="false">
                            <img :src="dataUrlSelfieBase64" alt="selfie" v-if="dataUrlSelfieBase64.length>0" style="width:100%"/>
                        </div>
                        <div class="col-lg-12"  v-if="!isMobil">
                        <img src="../assets/icono-camara-gris.png" alt="cámara" @click="startCameraStream($event)" style="cursor: pointer; width: 135px"
                            v-show="!isCameraOpen" v-if="dataUrlSelfieBase64.length==0" />
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-if="!isMobil">
                    <img :src="dataUrlSelfieBase64" alt="selfie" v-if="dataUrlSelfieBase64.length>0" />
                    
                    <div v-show="isCameraOpen">
                    <br/>
                    <div>
                        <button class="btn btn-danger mb-3 mollen-font" @click="cancelarCaptura($event)">Cancelar</button>&nbsp;
                        <button class="btn btn-success mb-3 mollen-font" @click="capture($event)">Capturar selfie</button>
                    </div>
                    <div class="camera-box" style="margin-top: 65px">
                        <div class="camera-canvas">
                            <div style="postion: absolute; width: 100%; height: 380px; background-size: 500px 380px; opacity: 0.5; z-index: 300000; background-repeat:no-repeat; background-position: center center; background-image: url('/img/silueta.png');"></div>
                            <video ref="camera" :width="canvasWidth" :height="canvasHeight" autoplay style="margin-top: -480px"></video>
                            <canvas v-show="false" id="photoTaken" ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
                        </div>
                    </div>
                    </div>
                    <div v-if="!isCameraOpen && dataUrlSelfieBase64.length>0">
                    <br/>
                    <button class="btn btn-info mb-3 mollen-font" @click="changeSelfie($event)">Cambiar selfie</button>
                    </div>
                </div>
                <!--FIN Campo de Selfie-->
                <hr>
                <div style="text-align:left" v-if="menorEdad">
                    <b>NOTA:</b> Las fotografías de tu identificación oficial y la selfie del menor deberán ser capturadas con tu dispositivo. 
                </div>
                <div style="text-align:left" v-else>
                    <b>NOTA:</b> Las fotografías de tu identificación oficial y selfie deberán ser capturadas con tu dispositivo.
                </div>
                
                <div class="mb-3" style="text-align:left">
                  <b>Los campos marcados con <span style="color:#00852a;font-weight: 600;font-size: 18px;"> * </span> son obligatorios.</b>
                </div>
                <div class="mb-3">
                    <button type="submit" class="btn btn-success mb-3 mollen-font">Guardar</button>&nbsp;&nbsp;
                    <button type="button" @click="cancelarNuevoRegistro(2)" class="btn btn-danger mb-3 mollen-font">Cancelar</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { nextTick } from 'vue'
    import { StreamBarcodeReader } from "vue-barcode-reader";
    import {openModal} from "jenesius-vue-modal";
    import authService from  "../services/AuthService"
    import dataService from '../services/DataService'
    import detectaUsuarios from '../services/DetectaUsuarios'
    import Loading from 'vue-loading-overlay';
    import mainHeader from '../components/header'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Compressor from 'compressorjs';
    //import Modal from "./Modal.vue";
    import ModalWarning from "./ModalWarning.vue";
    import ModalShowExp from "./ModalShowExp.vue"
    import ModalPrintQR from "./ModalPrintQR.vue";
    //import axios from 'axios';

    export default {
        name: 'CheckTickets',
        components: {
            StreamBarcodeReader, Loading, mainHeader
        },
        props: {
            msg: String
        },
        data() {
            return {
                checkMayor: false,
                isLoading: false,
                fullPage: true,
                esMayorEdad: false, 
                isMobil: false,
                isCameraOpenIne: false,
                isCameraOpen: false,
                canvasHeight:480,
                canvasWidth:600,
                items: [],
                dataUrlIneBase64: "", 
                dataUrlSelfieBase64: "", 
                wrongEmail: false,
                formStatus: false,
                aviso : "https://miseleccion.mx/aviso-de-privacidad-asistencia-al-estadio",
                conducta : "https://fmf.mx/docs/Reglamentos/Codigo_de_Etica_2017_1.pdf",
                inputCode : true,
                loading : false,
                start : true,
                text : "",
                mayorEdad : false,
                menorEdad : false,
                erroTicket : false,
                error: false,
                textError:"",
                textTicket:"",
                id: null,
                avisoPriv : false,
                codConducta : false,
                eventMatch : '',
                form: {
                    barCodeId:'',
                    name: '',
                    last_name:'',
                    m_last_name:'',
                    nameTutor:'',
                    email: '',
                    mobile: '',
                    isChild: false,
                    ine: null,
                    photo: null
                },
                selectedMonth: -1,
                selectedYear: '0',
                meses: ['Enero','Febrero','Marzo','Abril','Mayo','Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
                years: [],
                msgsExpl: [], // array de mensajes al ser detectado entre los expulsados
                peticionImg1: null,
                peticionImg2: null,
                rutaImgExpulsado: ''
            };
        },
        methods: {
            salir(){
                authService.logout()
                this.$router.push("/")
            },
            cancelarNuevoRegistro(opc){
                if(opc==1){
                    this.$router.push("/checkTicket")
                } else {
                    this.$router.go()
                }
            },
            onCancel() {
                console.log('User cancelled the loader.')
            },
            startCameraStream(ev, ineSelfie) {
            ev.preventDefault()
            this.form.photo = '';

              if(ineSelfie == 'ine' && document.getElementById('inputIne')){
                document.getElementById('inputIne').value ="";
              }
              if(ineSelfie == 'selfie' && document.getElementById('inputFoto')){
                document.getElementById('inputFoto').value ="";
              }
                const constraints = (window.constraints = {
                    audio: false,
                    video: true
                });
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(stream => {
                      if(ineSelfie=='ine'){
                        this.$refs.cameraIne.srcObject = stream;
                      } else {
                        this.$refs.camera.srcObject = stream;
                      }
                    }).catch(error => {
                    alert("Browser doesn't support or there is some errors." + error);
                });
                if(ineSelfie=='ine'){
                  this.isCameraOpenIne = true
                  this.isCameraOpen = false
                } else {
                  this.isCameraOpenIne = false
                  this.isCameraOpen = true;
                }
            },

            stopCameraStream(ineSelfie) {
                let tracks = null
                if(ineSelfie=='ine'){
                  tracks = (this.$refs.cameraIne.srcObject ? this.$refs.cameraIne.srcObject.getTracks(): null)
                } else {
                  tracks = (this.$refs.camera.srcObject ? this.$refs.camera.srcObject.getTracks(): null)
                }
                if(tracks!=null){
                  tracks.forEach(track => {
                      track.stop()
                      console.log(track)
                  });
                } else {
                  if(ineSelfie=='ine'){
                    this.dataUrlIneBase64 = ''
                  } else {
                    this.dataUrlSelfieBase64 = ''
                  }
                }
                if(ineSelfie=='ine'){
                  this.isCameraOpenIne = false
                } else {
                  this.isCameraOpen = false
                }
            },
            changeSelfie(ev, ineSelfie){
              ev.preventDefault()
              this.startCameraStream(ev, ineSelfie)
            },
            cancelarCaptura(ev, ineSelfie){
              ev.preventDefault()
              if( ineSelfie=='ine' ){
                this.dataUrlIneBase64=''
              }else{
                this.dataUrlSelfieBase64=''
              }
              this.stopCameraStream(ineSelfie)
            },
            capture(ev, ineSelfie) {
              ev.preventDefault()
                const FLASH_TIMEOUT = 50;
                let self = this;
                setTimeout(() => {
                    const context = ineSelfie=='ine' ? self.$refs.canvasIne.getContext('2d'): self.$refs.canvas.getContext('2d');
                    context.drawImage(ineSelfie=='ine' ? self.$refs.cameraIne: self.$refs.camera, 0, 0, self.canvasWidth, self.canvasHeight);
                    if( ineSelfie=='ine' ){
                      self.dataUrlIneBase64 = self.$refs.canvasIne.toDataURL("image/jpeg")
                      console.log(self.dataUrlIneBase64);
                    }else{
                      self.dataUrlSelfieBase64 = self.$refs.canvas.toDataURL("image/jpeg")
                      console.log(self.dataUrlSelfieBase64);
                    }
                        //.replace("image/jpeg", "image/octet-stream");
                    
                    //self.addToPhotoGallery(dataUrl);
                    //self.uploadPhoto(dataUrl);
                    self.stopCameraStream(ineSelfie);
                }, FLASH_TIMEOUT);
            },
            dataURLtoFile(dataURL, filename) {
                let arr = dataURL.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
 
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {type: mime});
            },
            destroy(){
                console.log("destroy");
                let tracks = this.$refs.scanner.srcObject.getTracks();
                console.log(tracks)
                tracks.forEach(track => {
                    track.stop()
                });
            },

            async verificar(e){
                e.preventDefault()
                if(!this.esMayorEdad){ return }
                if( !(this.form.barCodeId.length==14 || this.form.barCodeId.length==18 || this.form.barCodeId.length==22) || this.form.barCodeId.match(/(\d)\1{5,}/) != null){
                    openModal(ModalWarning,{
                        title:"Lectura de Boleto",
                        data: "El número de boleto ingresado no es correcto.",
                        borrarReg : false
                    });
                } else 
                if(this.form.barCodeId == null || this.form.barCodeId.length == ""){
                    openModal(ModalWarning,{
                        title:"Lectura de Boleto",
                        data: "Ingrese un número de boleto correcto para validar",
                        borrarReg : false
                    });
                } else {
                    if(await this.validateTicket(this.form.barCodeId)){
                        this.selectedMonth = localStorage.mes ? localStorage.mes: -1;
                        this.selectedYear = localStorage.year ? localStorage.year: '0';
                        this.loading = false;
                        this.erroTicket = false;
                        this.inputCode = false;
                        this.formStatus = true;
                        this.textTicket = "";
                        await nextTick(()=>{
                            this.$refs.refInputNombre.focus()
                        })
                    } else {
                        this.erroTicket = true;
                    }
                }
            },
            changeImg(ev, ineSelfie){
                //console.log(ineSelfie)
                const file = ev.target.files[0];
                let url = URL.createObjectURL(file);
                if(ineSelfie=='ine'){
                    this.dataUrlIneBase64 = url
                } else {
                    this.dataUrlSelfieBase64 = url
                }
                //console.log(url)
            },
            scan(){
                this.inputCode = false;
                this.form.barCodeId = "";
                this.loading = true;
                this.scanKey += 1;
            },
            textManual(){
                this.inputCode = true;
                this.loading = false;
            },
            async onDecode(a, b, c) {
                this.scanKey += 1;
                console.log(this.scanKey)
                console.log(a)
                if(this.form.barCodeId ==! a){
                    console.log(a, b, c);
                    this.loading = false;
                    this.inputCode = true;
                    this.form.barCodeId = a;
                    
                }
                this.text = a;
                if (this.id) clearTimeout(this.id);
                    this.id = setTimeout(() => {
                    if (this.text === a) {
                        this.text = "";
                    }
                }, 1000);
            },
            onLoaded() {
                console.log("load");
            },
            changeMenor(){
                this.menorEdad = true
                this.mayorEdad = false
                this.form.isChild = true
                this.$refs.refInputNombreTutor.focus()
            },
            changeMayor(){
                this.menorEdad = false
                this.form.isChild = false
                this.mayorEdad = true
                this.$refs.refInputNombre.focus()
            },
            async validateTicket(ticket){
                var result = false;
                this.isLoading = true
                //await axios.get(`https://tdevs.apilmx.com/check/${this.$CryptoJS.SHA512(ticket).toString()}`)
                await dataService.getCheck(this.$CryptoJS.SHA512(ticket).toString())
                .then(response => {
                    if(response.status == 200){
                        this.isLoading = false
                        result =  false
                        this.form.barCodeId = ticket;
                        this.eventMatch = response.data.match;
                        if(response.data.emailConfirmed){
                            openModal(ModalWarning,{
                                title:"Boleto registrado",
                                data: `El boleto ${response.data.barCodeId} Ya fue registrado por ${response.data.name} ${response.data.last_name} ${response.data.m_last_name}`,
                            });
                        } else {
                            result =  true
                            this.peticionImg1 = response.data.id
                            this.peticionImg2 = response.data.selfie
                        }
                    }
                })
                .catch(e => {
                    this.isLoading = false
                    result =  false;
                    if(e.response.status==401){
                        this.salir()
                    } else if(e.response.status==400 && e.response.data.message.indexOf('no ha sido registrado')>=0){
                        result =  true
                        this.form.barCodeId = ticket;
                        this.eventMatch = e.response.data.match;
                        this.peticionImg1 = e.response.data.id
                        this.peticionImg2 = e.response.data.selfie
                    } else {
                        openModal(ModalWarning,{
                            title: e.response.data.message,
                            data: ""
                        })
                    }
                    
                })
                console.log(result);
                return result;
            },
            async submitForm(e){
                e.preventDefault()
                this.rutaImgExpulsado=""
                this.msgsExpl=[]
                if(!document.getElementById('inputINE')){
                  //console.log(this.dataUrlIneBase64)
                    if(this.dataUrlIneBase64.length==0){
                        return; 
                    }
                }
                this.form.ine = document.getElementById('inputINE') && document.getElementById('inputINE').value.length>0 ? 
                    document.getElementById('inputINE').value: this.dataUrlIneBase64;
                this.form.photo = document.getElementById('inputFoto') && document.getElementById('inputFoto').value.length>0 ? 
                    document.getElementById('inputFoto').value: this.dataUrlSelfieBase64;
                
                if(this.validateForm()){
                    this.isLoading = true
                    let userT = this.form.name+ ' ' + this.form.last_name
                    let emailT = this.form.email
                    let resp = detectaUsuarios.getUserDetected(userT, emailT)
                    if(resp.length>0 && resp[0].value){
                        // Aqui vamos a guardar los datos del this.form en los logs, esto por detectar correo de expulsado
                        this.showModalExp(userT, emailT, "", "")
                        return
                        /* // QUE YA NO VA -.-
                        resp.forEach(respMsg=>{
                            this.msgsExpl.push(respMsg.msg)
                        })
                        */
                    }

                    this.form.ine = document.getElementById('inputINE') && document.getElementById('inputINE').value.length>0 ? 
                        document.getElementById('inputINE').value: this.dataUrlSelfieBase64;
                    this.form.photo = document.getElementById('inputFoto') && document.getElementById('inputFoto').value.length>0 ? 
                        document.getElementById('inputFoto').value: this.dataUrlSelfieBase64;
                    


                    // PETICION 2 para la SELFIE
                    //console.log(this.peticionImg2)
                    let urlUpld2 = (this.peticionImg2.url)
                    let fields2 = (this.peticionImg2.fields)
                    var bodyFormData2 = new FormData()
                    //console.log(urlUpld2)
                    //console.log(fields2)
                    var nameSelfie = ""
                    Object.entries(fields2).forEach(([key, value]) => {
                        //console.log(key + ' - ' + value)
                        if(key=='key'){
                            nameSelfie = value.replace(".jpg", "")
                        }
                        bodyFormData2.append(key, value);
                    });
                    if( document.getElementById('inputFoto') && document.getElementById('inputFoto').files.length>0 ){
                        bodyFormData2.append('file', await this.compress(document.getElementById('inputFoto').files[0]), document.getElementById('inputFoto').files[0].name);
                    } else {
                        // Aqui significa que esta en la PC y tomaron selfie con la webcam
                        let capturedPhotoFile = this.dataURLtoFile(this.dataUrlSelfieBase64, 'selfie.jpg')
                        //console.log(capturedPhotoFile)
                        //bodyFormData2.append('file', await this.compress(capturedPhotoFile), capturedPhotoFile.name);
                        bodyFormData2.append('file', capturedPhotoFile)
                    }
                    dataService.uploadImg(urlUpld2, bodyFormData2)
                    .then(async (res) => {
                        if(res.status==204) {
                            //Se subio la selfie correctamente
                            console.log(this.msgsExpl)
                            //Una vez que la imagen de la selfie se haya subido con exito vamos a ejecutar 
                            // servicio para buscar la selfie y comparar con los expulsados del estadio
                            dataService.search({selfie: nameSelfie})
                            .then(async respSearch=>{
                                console.log(respSearch.status)
                                if(respSearch.status == 200){
                                    //Subir img de indentificacion
                                    this.uploadIdnt(nameSelfie)
                                } else {
                                    this.isLoading = false
                                    this.showMsg("Atención", "El registro no puede ser realizado.")
                                }
                            })
                            .catch(err=>{
                                //console.log(err.response)
                                this.isLoading = false
                                if(err.response.status==500){
                                    this.showMsg("Atención", "Lo sentimos. La fotografía/selfie no cumple con los requisitos. Favor de tomarla una vez más e intentarlo de nuevo.")
                                } else {
                                    //this.msgsExpl.push("Fotografía")  // Esto ya no va... va para logs, detectado por imagen
                                    //Subir img de indentificacion
                                    this.rutaImgExpulsado = err.response.data.url_match
                                    //this.uploadIdnt(nameSelfie)
                                    this.showModalExp(userT, emailT, err.response.similarity, err.response.url_match)
                                }
                            })
                            
                        }
                    })
                    .catch(err=>console.log(err))
                }
            },
            async uploadIdnt(nameSelfie){
                // Subir imagen de identificacion
                // aqui va peticion para imagen 2, ya que NO es uno de los expulsados... seguimos adelante
                // PETICION 1 para la IDENTIFICACION
                let urlUpld1 = (this.peticionImg1.url)
                let fields1 = (this.peticionImg1.fields)
                var bodyFormData1 = new FormData()
                //console.log(urlUpld1)
                //console.log(fields1)
                var nameIdnt=""
                Object.entries(fields1).forEach(([key, value]) => {
                    //console.log(key + ' - ' + value)
                    if(key=='key'){
                        nameIdnt = value.replace(".jpg", "")
                    }
                    bodyFormData1.append(key, value);
                });
                if( document.getElementById('inputINE') && document.getElementById('inputINE').files.length>0 ){
                    bodyFormData1.append('file', await this.compress(document.getElementById('inputINE').files[0]), document.getElementById('inputINE').files[0].name);
                } else {
                    console.log(this.dataUrlIneBase64)
                    // Aqui significa que esta en la PC y tomaron foto de la identificacion con la webcam
                    let capturedIneFile = this.dataURLtoFile(this.dataUrlIneBase64, 'identificacion.jpg')
                    //console.log(capturedIneFile)
                    //bodyFormData1.append('photo', await this.compress(capturedIneFile), capturedIneFile.name);
                    bodyFormData1.append('file', capturedIneFile)
                }
                dataService.uploadImg(urlUpld1, bodyFormData1).then((res) => {
                    if(res.status==204) {
                        this.continuaRegistro(nameIdnt, nameSelfie)
                        // Se subio la imagen de identificacion
                        /*
                        // Aqui se detetcaban mesanjes por los que se habia detectado un expilsado, hicieron se quitara -.-
                        //Revisar si hay mensajes de expulsados mostrarlos en un emergente y tener la oción de continuar
                        if(this.msgsExpl.length>0){
                            this.isLoading = false
                            //Hay coincidencias con expulsados
                            console.log(this.rutaImgExpulsado)
                            this. show Modal Exp()
                        } else {
                            //Seguimos normal
                            this.continuaRegistro(nameIdnt, nameSelfie)
                        }
                        */
                    }
                })
                .catch(err=>console.log(err))
            },
            continuaRegistro(nameIdnt, nameSelfie){
                /* Aquí vamos a revisar si es menor ir al registro */
                var bodyFormData = new FormData();
                bodyFormData.append('barCodeId',this.form.barCodeId);
                bodyFormData.append('hash',this.$CryptoJS.SHA512(this.form.barCodeId).toString());
                bodyFormData.append('name',this.form.name);
                bodyFormData.append('last_name',this.form.last_name);
                bodyFormData.append('m_last_name',this.form.m_last_name);
                bodyFormData.append('email',this.form.email);
                bodyFormData.append('mobile',this.form.mobile);
                bodyFormData.append('isChild',this.form.isChild);
                if(this.form.isChild){
                    bodyFormData.append('tutor', this.form.nameTutor);
                }
                bodyFormData.append('date', this.meses[this.selectedMonth] + "/" + this.selectedYear);
                bodyFormData.append('key_id', nameIdnt);
                bodyFormData.append('key_selfie', nameSelfie);
                /* AQUI VALIDAMOS SI ES MENOR NO SE DEBE USAR REKOGNITION para comparar las caras */
                if(this.form.isChild){
                    // Por lo tanto vamos a proceder con el registro
                    dataService.register(bodyFormData)
                    .then((resp)=>{
                        this.isLoading = false
                        //console.log(resp)
                        if(resp.status==200){
                            if(resp.data.ticket){
                                openModal(
                                    ModalPrintQR, {
                                    imagenQr: resp.data.ticket, 
                                    numeroBoleto: resp.data.barCodeId,
                                    nombre: resp.data.name + " " + resp.data.last_name + " " + resp.data.m_last_name,
                                    cancelar:  ()=>{
                                        openModal(ModalWarning, {
                                            title: "Confirmación",
                                            data: "Los datos se guardaron correctamente",
                                            actualizados: true,
                                            updated: () => {
                                                this.$router.push("/checkTicket");
                                            },
                                        });
                                    },
                                    completed: ()=>{
                                        this.$router.push("/checkTicket")
                                    }
                                })
                            } else {
                                openModal(ModalWarning, {
                                    title: "Ocurrió un problema con el QR",
                                    data: "Edite el registro para generar el QR nuevamente",
                                    actualizados: true,
                                    updated: () => {
                                        this.$router.push("/checkTicket");
                                    },
                                });
                            }
                        }
                    })
                    .catch((e)=>{
                        console.log(e)
                        this.isLoading = false
                        if(e.response.status==401){
                            this.salir()
                        }
                        openModal(ModalWarning, {
                            title: "Registro no exitoso",
                            data: e.response.data.error
                        });
                    })

                } else {

                    //Una vez que se haya subido la identificacion vamos a comparar las imagenes selfie vs identificacion
                    dataService.compare({id: nameIdnt, selfie: nameSelfie})
                    .then(respCmpr=>{
                        //console.log(respCmpr.status)
                        if(respCmpr.status==200){
                            // la identificacion y la selfie corresponden a la misma persona
                            // Por lo tanto vamos a proceder con el registro
                            dataService.register(bodyFormData)
                            .then((resp)=>{
                                this.isLoading = false
                                //console.log(resp)
                                if(resp.status==200){
                                    if(resp.data.ticket){
                                        openModal(
                                            ModalPrintQR, {
                                            imagenQr: resp.data.ticket, 
                                            numeroBoleto: resp.data.barCodeId,
                                            nombre: resp.data.name + " " + resp.data.last_name + " " + resp.data.m_last_name,
                                            cancelar:  ()=>{
                                                openModal(ModalWarning, {
                                                    title: "Confirmación",
                                                    data: "Los datos se guardaron correctamente",
                                                    actualizados: true,
                                                    updated: () => {
                                                        this.$router.push("/checkTicket");
                                                    },
                                                });
                                            },
                                            completed: ()=>{
                                                this.$router.push("/checkTicket")
                                            }
                                        })
                                    } else {
                                    openModal(ModalWarning, {
                                        title: "Ocurrió un problema con el QR",
                                        data: "Edite el registro para generar el QR nuevamente",
                                        actualizados: true,
                                        updated: () => {
                                            this.$router.push("/checkTicket");
                                        },
                                    });
                                    }
                                }
                            })
                            .catch((e)=>{
                                console.log(e)
                                this.isLoading = false
                                if(e.response.status==401){
                                this.salir()
                                }
                                openModal(ModalWarning, {
                                    title: "Registro no exitoso",
                                    data: e.response.data.error
                                });
                            })

                        } else {
                            this.isLoading = false
                            this.showMsg("Atención", "La selfie no corresponde a la foto de la identificación.")
                        }
                    }).catch(err=>{
                        console.log(err)
                        this.isLoading = false
                        if(err.response.status==500){
                            this.showMsg("Atención", "Lo sentimos. La fotografía de tu identificación oficial no cumple con los requisitos. Favor de tomarla una vez más e intentarlo de nuevo.")
                        } else {
                            this.showMsg("Atención", "Lo sentimos. La fotografía/selfie no coincide con tu identificación oficial. Favor de tomarla nuevamente e intentarlo de nuevo. Si el problema persiste, por favor repórtalo a ayuda@miseleccion.mx")
                        }
                    })

                }  
            },
            showModalExp(userT, emailT, similarity, url_match){
                this.isLoading = false
                openModal(ModalShowExp,{
                    title:"Atención",
                    //data: "Por favor localice al Coordinador de la Mesa de Ayuda.",
                    data: "De acuerdo a la información ingresada, nuestros registros indican que en un partido previo incumpliste con el “Código de Comportamiento en el Estadio”. Por lo anterior, tu registro no puede ser completado y tu acceso al estadio no será permitido.",
                    msgs: this.msgsExpl,
                    name: userT,
                    email: emailT,
                    similarity: similarity,
                    url_match: url_match,
                    rutaImgExpulsado: this.rutaImgExpulsado, 
                    continuar:  ()=>{
                        console.log("Ya NO continuar con el registro.")
                        //this.isLoading = true
                        //this.continuaRegistro(nameIdnt, nameSelfie)
                    },
                    cancelar: ()=>{
                        console.log("Cancelar registro.")
                    }
                })
            },
            showMsg(psTitle, msg){
                openModal(ModalWarning,{
                    title: psTitle,
                    data: msg,
                    borrarReg : false
                });
            },
            isEmailValid() {
                const emailRe = /^\S+@\S+\.\S+$/;
                if (emailRe.test(this.form.email)) {
                    this.wrongEmail = false;
                } else {
                    this.wrongEmail = true;
                }
            },
            compress(file){
                return new Promise((resolve,reject) => {
                    new Compressor(file, {
                        quality: 0.6,
                        success(result) {
                            resolve(result)
                        },
                        error(err) {
                            reject(err)
                        },
                    });
                })
            },
            validateForm(){
                var numTel = this.form.mobile.toString().length;
                var validation = !(numTel == 10);
                console.log()
                if(this.form.isChild && this.form.nameTutor === "" ){
                    this.showMsg("Falta Información", "Debe capturar el nombre completo con apellidos del responsable del menor")
                    return false
                } else if(numTel > 0 && validation){
                    this.showMsg("Falta Información", "Registra un número de celular válido a 10 dígitos")
                    return false
                } else if(this.form.name === "" || this.form.last_name === ""){
                    this.showMsg("Falta Información", "Registra el nombre y apellido paterno correctamente")
                    return false
                } else if(this.form.email === ""){
                    this.showMsg("Falta Información", "Ingresa el correo electrónico correctamente")
                    return false
                } else if(this.form.photo.length == 0 || this.form.ine.length == 0){
                    this.showMsg("Falta Información", "Tome las fotos correctamente")
                    return false
                } else if(this.selectedMonth == -1 || this.selectedYear==0){
                    this.showMsg("Falta Información", "Ingresa la fecha de nacimiento correctamente")
                    return false
                } else{
                    return true; 
                }
            },
            
            isMobilDevice(){
                return ( navigator.userAgent.match(/Android/i)
                    || navigator.userAgent.match(/webOS/i)
                    || navigator.userAgent.match(/iPhone/i)
                    || navigator.userAgent.match(/iPad/i)
                    || navigator.userAgent.match(/iPod/i)
                    || navigator.userAgent.match(/BlackBerry/i)
                    || navigator.userAgent.match(/Windows Phone/i)
                )
            },
            putYears(){
                this.years = []
                let hoy = new Date()
                let yearInicio = hoy.getFullYear()-18
                let yearHasta = yearInicio-90;
                for(let i=yearInicio; i>yearHasta; i--){
                    this.years.push(i)
                }
            }
        },
        mounted(){
            if (localStorage.esMayor) {
                this.checkMayor = localStorage.esMayor
            }
            if( navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/Windows Phone/i)
            ){
                this.isMobil=true
            }
            this.esMayorEdad = true
            this.mayorEdad = true;
            this.putYears()
            this.$refs.inputBarCodeId.focus()
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.error{
    color:red;
}
.ticketError{
    color:red;
    font-size: large;
}
.boton{
    cursor: pointer;
    border: 1px solid black;
}
input[type=checkbox], input[type=radio] {
    vertical-align: -2px;
    margin: 0;
    padding: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
