class DetectaUsuarios{

    getUserDetected(user, email){
        let noAcceso = [
            {
                "email": "alexitopq62@gmail.com"
            },
            {
                "email": "carlosgonzalezalvite@gmail.com"
            },
            {
                "email": "jcarlos.galvite@gmail.com"
            },
            {
                "email": "gisama_85@hotmail.com"
            },
            {
                "email": "luflores.924@gmail.com"
            },
            {
                "email": "luis.tellez@g500network.com"
            },
            {
                "email": "emilioraphael1@gmail.com"
            },
            {
                "email": "jose.perez.23.84@gmail.com"
            },
            {
                "email": "miki9326@gmail.com"
            },
            {
                "email": "guille_cd_satelite@hotmail.com"
            },
            {
                "email": "alan.mhz@hotmail.com"
            },
            {
                "email": "tenisyropalyl@gmail.com"
            },
            {
                "email": "escrituracion.egma@gmail.com"
            },
            {
                "email": "diegogongora25@icloud.com"
            },
            {
                "email": "solo_069@hotmail.com"
            },
            {
                "email": "melgarobed71@gmail.com"
            },
            {
                "email": "maquitos_75@hotmail.com"
            },
            {
                "email": "mapepi2000@hotmail.com"
            },
            {
                "email": "ysa_1785@hotmail.com"
            }
        ]
        let userDetected = []
        //let userToFind = user.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g,"").toLowerCase()
        let mailToFind = email.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g,"").toLowerCase()
        noAcceso.forEach(user=>{
        let mailNoAccss = (user.email).normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g,"").toLowerCase()
        /*
        let usrNoAccss = (user.name+user.last_name).normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g,"").toLowerCase()
        if(userToFind===usrNoAccss){
            userDetected.push({msg: "Nombre y apellidos" , value: true})
        }
        */
        if(mailToFind===mailNoAccss){
            userDetected.push({msg: "Correo Electrónico" , value: true})
        }
        })
        return userDetected
    }
}
export default new DetectaUsuarios();