<template>
    <div class="cont">
        <loading
            v-model:active="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            :width="150"
            color="#02b348"
            loader="dots"
        />
        <form action="imprimir" class="style-caja">
            <div>
                <div class="printCenter" style="text-align:center">
                    <h4 style="margin-top:50px ticket-space">Número de boleto</h4>
                    <p class="ticket-space">{{numeroBoleto}}</p>
                    <h4>Nombre</h4>
                    <p>{{nombre}}</p>
                    <img :src="imagenQrLocal" alt="qr" style="width:50%" v-if="imagenQrLocal && imagenQrLocal.length>0"/>
                    <qrcode-vue :value="qrNew" :size="300" level="H" render-as="svg" v-else/>
                    <br><br>
                    <p><b>Acepto y he leído el Aviso de Privacidad y Código de Conducta</b></p>
                </div>
            </div>
            <div id="printMe" v-show="imagenQrLG">
                <div class="printCenter" style="text-align:center">
                    <h4 style="margin-top:25px ticket-space">Número de boleto</h4>
                    <p class="ticket-space">{{numeroBoleto}}</p>
                    <h4>Nombre</h4>
                    <p>{{nombre}}</p>
                    <img :src="imagenQrLocal" alt="qr" style="width:100%" v-if="imagenQrLocal && imagenQrLocal.length>0"/>
                    <qrcode-vue :value="qrNew" :size="300" level="H" render-as="svg" v-else/>
                    <br><br>
                    <p><b>Acepto y he leído el Aviso de Privacidad y Código de Conducta</b></p>
                </div>
            </div>
            <br>
            <button type="button" @click="imprimir(), popModal()" class="btn btn-success mb-3 mollen-font">
                {{user && user.rol=='admin' ? 'Autorizar': 'Imprimir'}}
            </button>&nbsp;
            <button type="button" @click="cancelar(), popModal()" class="btn btn-danger mb-3 mollen-font">Cancelar</button>
        </form>
    </div>
</template>
<script>
    import dataService from '../services/DataService'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {popModal} from "jenesius-vue-modal";
    import QrcodeVue from 'qrcode.vue'

    export default {
        name: 'ModalPrintQR',
        components: {
            Loading, QrcodeVue
        },
        props: {
            cancelar : Function,
            completed : Function,
            numeroBoleto:String,
            nombre:String,
            imagenQr: String
        },
        data(){
            return{
                isLoading: false,
                fullPage: true,
                avisoPriv: false,
                user: null,
                imagenQrLG: false,
                imagenQrLocal: "",
                qrNew: ""
            }
        },
        setup(){
            return {
                popModal
            }
        },
        methods: {
            onCancel() {
                console.log('User cancelled the loader.')
            },
            async imprimir () {
                if(this.user.rol == 'admin' ){
                    this.validar()
                } else {
                    await this.$htmlToPaper('printMe', null, ()=>{
                        this.validar()
                    });
                }
            },
            validar(){
                this.isLoading = true
                // Vamos a validar este boleto que estan imprimiendo su QR
                dataService.setValid(this.$CryptoJS.SHA512(this.numeroBoleto).toString())
                .then(()=>{
                    //console.log(resp.data)
                    this.completed()
                    this.isLoading = false
                })
                .catch(()=>{
                    //console.log(e)
                    this.isLoading = false
                });
            }
        },
        mounted() {
            this.user = JSON.parse(localStorage.getItem('user'))
            //console.log(this.imagenQr)
            //console.log(this.numeroBoleto)
            if(this.imagenQr){
                //console.log("Existe QR")
                this.imagenQrLocal = this.imagenQr
            } else {
                //console.log("No Existe QR")
                
                this.qrNew = this.$CryptoJS.SHA512(this.numeroBoleto).toString()
                /*
                dataService.generarQR(this.$CryptoJS.SHA512(this.numeroBoleto).toString())
                .then(resp=>{
                    this.isLoading = false
                    //console.log(resp.data.response)
                    this.$nextTick(() => {
                        this.imagenQrLocal = resp.data.response
                    });
                })
                .catch(e=>{
                    console.log(e)
                    this.isLoading = false
                })
                */
            }
            //console.log(" --> " + this.imagenQrLocal)
        }
    }
</script>
<style>
    .cont{
        background-color: white;
        border: ridge 1px black;
        padding: 2%;
    }
</style>