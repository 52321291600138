<template>
  <router-link to="/">
        <img alt="Vue logo" src="@/assets/header-page-fmf-2.png" class="marginImg img-fluid">
  </router-link>
</template>

<script>
export default {
    name:"headerSmall",
    components: {
    },
    data() {
      return {
        user: null
      }
    },
    methods:{
      
    },
    mounted(){
      this.user = localStorage.getItem('user')
      if(this.user && location.pathname=='/index.html'){
        this.$router.push("/")
      }
    }
}
</script>

<style>

</style>