<template>
  <div>
   <div class="content-body">
      <router-view />
      <widget-container-modal />
   </div>
   <footer style="min-height: 100px;background-color:#01413b; min-width:100% !important; border-width:10px 5px; border-style:solid; border-color:transparent">
      <div class="container-fluid">
         <div class="footer mt-20">
            <div class="row">
               <div class="col-md-3 col-xs-12 col-sm-12 col-12" style="color:white;align-self: center !important;">
                  <table style="margin: 0 auto;">
                     <tbody>
                        <tr class="" style="">
                           <td class="" style="border-collapse:collapse; padding:5px 5px"><a href="https://www.instagram.com/miseleccionmx/" target="_blank" rel="noopener noreferrer" class="https://www.instagram.com/miseleccionmx/"><img src="https://lmxwebsite.s3.amazonaws.com/images/mailingfmf/instagram.png" alt="Instagram" width="24" height="24" class="" style="outline:0px; border:none; display:block; width:24px!important; height:24px!important"></a></td>
                           <td class="" style="border-collapse:collapse; padding:5px 5px"><a href="https://www.facebook.com/MiSeleccionMX" target="_blank" rel="noopener noreferrer" class=""><img src="https://lmxwebsite.s3.amazonaws.com/images/mailingfmf/facebook.png" alt="Facebook" width="24" height="24" class="" style="outline:0px; border:none; display:block; width:24px!important; height:24px!important"></a></td>
                           <td class="" style="border-collapse:collapse; padding:5px 5px"><a href="https://twitter.com/miseleccionmx" target="_blank" rel="noopener noreferrer" class=""><img src="https://lmxwebsite.s3.amazonaws.com/images/mailingfmf/twitter.png" alt="Twitter" width="24" height="24" class="" style="outline:0px; border:none; display:block; width:24px!important; height:24px!important"></a></td>
                           <td class="" style="border-collapse:collapse; padding:5px 5px"><a href="https://www.youtube.com/c/miseleccionmx/" target="_blank" rel="noopener noreferrer" class=""><img src="https://lmxwebsite.s3.amazonaws.com/images/mailingfmf/youtube.png" alt="YouTube" width="24" height="24" class="" style="outline:0px; border:none; display:block; width:24px!important; height:24px!important"></a></td>
                           <td class="" style="border-collapse:collapse; padding:5px 5px"><a href="https://www.tiktok.com/@miseleccionmx" target="_blank" rel="noopener noreferrer" class=""><img src="https://lmxwebsite.s3.amazonaws.com/images/mailingfmf/tiktok.png" alt="Tiktok" width="24" height="24" class="" style="outline:0px; border:none; display:block; width:24px!important; height:24px!important"></a></td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <div class="col-md-3 col-xs-12 col-sm-12 col-12" style="color:white;text-align: center;">
                  <a href='https://miseleccion.mx/aviso-de-privacidad-asistencia-al-estadio' style="color: white;" target="_blank">Aviso de Privacidad</a>
               </div>
               <div class="col-md-6 col-xs-12 col-sm-12 col-12" style="color:white;align-self: center !important;">
                  <table style="margin: 0 auto;">
                     <tbody>
                        <tr>
                           <td style="min-width: 265px; padding-right:10px;"><span class="bold-Grotesque">Selección Nacional de México</span></td>
                           <td><div style="border-left: 2px solid #f7f7f7;min-height: 25px;padding-left: 10px;padding-right: 10px;"></div></td>
                           <td><img src="https://lmxwebsite.s3.amazonaws.com/images/mailingfmf/fmf-logo-footer.png" alt="Logo FMF" class="img-fluid" style="outline:0px; border:none; display:block;min-width:40px; min-height: 20px;max-width: 60px;"> </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   </footer>
  </div>
</template>

<script>
import {container} from "jenesius-vue-modal";
import "./assets/css/style.css";
export default {
  name: 'App',
  data(){
    return {
      user: null
    }
  },
  components: {
      WidgetContainerModal: container
  },
  methods: {
    
  }, 
  mounted(){
    
  }
}
</script>

<style>

</style>
